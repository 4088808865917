// FaildeControl.js
import React, { useEffect, useState } from 'react';
import GetRequest from '../components/apiConnections/getRequest';
import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom';
import QuestionCategoryInfo from './QuestionCategoryInfo';
import { Checkbox, Radio } from '@mui/material'
import Loader from "../Loader";
import { Box, Button, Card, CircularProgress, Menu, MenuItem, Modal, Grid, TextField, CardMedia, Container } from "@mui/material";
import { useTheme, } from "@mui/material/styles";
import Markdown from 'marked-react';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { TextareaAutosize } from "@mui/base";
import PostRequest from '../components/apiConnections/postRequest';




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  height: "80vh",
  overflowY: "auto",
};

const FaildeControl = () => {

  const data = useParams();
  const [failedControlData, setFailedControlData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [mainLoader, setMainLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendloading, setSendLoading] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [modelOpen, setModelOpen] = useState(null);
  const [generateRemedyLoader, setRemediationLoader] = useState(false);
  const theme = useTheme();
  const [selectedContactPerson, setSelectedContactPerson] = useState('')
  const [contactPersonList, setContactPersonList] = useState([])
  const [deadline, setDeadline] = useState('')


  useEffect(() => {
    getVendorFailedControl();
    getVendorContactPerson();
  }, []);

  const getVendorFailedControl = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_FAILED_CONTROL}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setFailedControlData(obj2);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          setshowLoader(false);
          setLoading(false)
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getVendorContactPerson = async () => {

    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  };


  const sendRemediation = () => {
    const contactPerson = JSON.parse(selectedContactPerson || '{}');

    if (deadline === ''){
      enqueueSnackbar("Please select deadline.", { variant: "error" }); 
      return;
    }
    if(!contactPerson?.email){
      enqueueSnackbar("Please select contact person.", { variant: "error" }); 
      return;
    }

    setSendLoading(true);
    
    const payload = {
      remediationPlan: modelOpen,
      firstName: contactPerson?.firstName,
      lastName: contactPerson?.lastName,
      email: contactPerson?.email,
      deadline,
      createdBy: localStorage.getItem('userEmail'),
      url: contactPerson?.vendorDomain,
    }


    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_REMEDIATION_BY_USER}`;

    PostRequest(url, payload)
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        setModelOpen(null);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  };


  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value)
  }

  const generateRemediation = () => {
    setRemediationLoader(true)
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_GENERATE_REMEDIATION}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setRemediationLoader(false)
          getVendorFailedControl()
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  }

  return (
    <div>
      <Loader show={mainLoader || showLoader} />
      <div className='tabpanel-full-size'>
        <div className="question-part-preview">
          <div className="inline-parts">
            <Button
              variant="contained"
              className="executive-report-btn"
              style={{ textAlign: "center", marginBottom: '100px', marginRight: "100px" }}
              onClick={() => generateRemediation()}
            >
              Generate Remediation
              {generateRemedyLoader && <CircularProgress size={12} sx={{ marginLeft: '15px' }} />}
            </Button>
          </div>
          {failedControlData.length ? failedControlData?.map((data3, j) => {
            return (
              <div key={j} className='question-card'>
                <div className='flex-preview-tab'>
                  <p className='preview-question-heading'>Q {j + 1}  </p>
                  <span className='data-text-preview'>{data3?.que.text}</span>
                </div>
                <QuestionCategoryInfo
                  data={data3?.que}
                  marginLeftTrue2={true}
                />
                {data3?.que.options ? data3?.que.options?.map((data4, k) => {
                  return (
                    <div key={k} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginLeft: '48px' }}>
                        <span className='preview-question-heading'> {(data3?.que.type || data3?.que?.optionType) === 'MCQ' ?
                          <Checkbox checked={data3.ans.includes(data4[k])} /> : <Radio checked={data3.ans == data4.title} />
                        } </span>
                        <span className='data-text-preview'>{data4.title}</span>
                      </div>

                    </div>
                  )
                }) : null}
                <div style={{ marginBottom: '20px', marginRight: '20px' }}>
                  <Button
                    onClick={() => setModelOpen(data3)}
                    style={{ textAlign: "center", marginLeft: '76%', width: '25%' }}
                  >
                    View Remediation
                  </Button>
                </div>
              </div>
            )
          }) : (
            !showLoader && (
              <h2 style={{ textAlign: "center", marginTop: '5rem' }}>No Record Found</h2>
            )
          )
          }
        </div>

      </div>

      <Modal
        open={modelOpen !== null}
        onClose={() => setModelOpen(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark"
            ? "dark-attack-modal modal-popup"
            : "light-attack-modal modal-popup"
        }
      >
        <Box sx={style} className="black-bg openai-response">
        <CloseIcon onClick={() => setModelOpen(null)}
            style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}
          />

          <form onSubmit={(e) => false} className='full-form' >
            <Grid container spacing={3}>
              <Grid item xs={6} md={6} lg={6}>
                <h3 className="relation" style={{ color: '#fff' }}>
                  <PermContactCalendarOutlinedIcon /> Vendor's Contact Person
                </h3>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {!selectedContactPerson ? "Select Person" : ""}
                  </InputLabel>
                  <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                  >
                    {contactPersonList.map((item) => (
                      <MenuItem key={item._id} value={JSON.stringify(item)}>
                        {item.firstName} {item.lastName} {`(${item.email})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <h3 className="relation" style={{ color: '#fff' }}>
                  <PermContactCalendarOutlinedIcon /> Deadline
                </h3>
                <FormControl fullWidth>
                  <TextField
                    style={{ color: '#ffff', fontWeight:600 }}
                    type="date"
                    defaultValue=""
                    onChange={(e) => setDeadline(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>



            <h3 className="relation" style={{ color: '#fff' }}><HandshakeOutlinedIcon /> Remediation
            </h3>
            <div>
              <ul>
                <li>
                  <h4>Objective: </h4> {modelOpen?.remediationPlan?.objective}
                </li>
                <li>
                  <h4>Action Items:</h4>
                  {modelOpen?.remediationPlan?.actionItems.map((item, index) => (
                    <ul key={index}>
                      <li>
                        <h4>Actions:</h4>
                        <ul>
                          <li>
                            <span><b>Responsible:</b></span> {item.responsible}
                          </li>
                          <li>
                            <span><b>Duration:</b></span> {item.duration}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ))}
                </li>

                <li>
                  <h4>Refrences:</h4>
                  {modelOpen?.remediationPlan?.references.map((item, index) => (
                    <ul key={index}>
                      <li>
                        <li>
                          <span><b>Refrence {index}:</b></span> {item}
                        </li>
                      </li>
                    </ul>
                  ))}
                </li>
              </ul>
            </div>

          </form>
          {/* </div> */}
          {/* </Container> */}

          <div className='remedy-send-btn' style={{ marginTop: '60px' }}>
            <Button
              variant="contained"
              className="executive-report-btn"
              onClick={() => sendRemediation()}
            >
              Send
            </Button>
          </div>

        </Box>

      </Modal>
   
    </div>
  );
}

export default FaildeControl;