import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import "./css/Vendors.css";

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
} from "@mui/material";
import * as React from "react";
import Switch from "@mui/material/Switch";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import PostRequest from "../components/apiConnections/postRequest";
import moment from "moment";
import Loader from "../Loader";
import { SnackbarProvider, useSnackbar } from "notistack";
import NewHeader from "../layouts/dashboard/header/NewHeader";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "requestAttackUrl", label: "URL", alignRight: false, filter: true, sort: true },
  { id: "requestBy", label: "Requested by", alignRight: false },
  { id: "requestDate", label: "Date Time", alignRight: false },
  { id: "status", label: "Fulfilled", alignRight: false },
  { id: "active", label: "Active", alignRight: false },
  // { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RequestsPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alertData, setAlertData] = useState({});
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    getRequestsforVendors();
  }, []);



  useEffect(() => {
  }, [vendorsData]);

  const getRequestsforVendors = async () => {
    setshowLoader(true);

    const endpoint = process.env.REACT_APP_GET_ALL_ATTACK_REQUESTS;

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setisNotFound(!(resVendors.data.data.length > 0));
            arrResponse.sort((a, b) =>
              a.requestDate > b.requestDate ? -1 : 1
            );
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.requestAttackUrl);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const filteredUsers = applySortFilter(vendorsData, getComparator(order, orderBy), filterName);

  const emptyRows =   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;


  const [checked, setChecked] = React.useState(true);

  const changeAttackRequestStatus = async (event, param1, param2, param3) => {
      setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        let endPoint = "attack-request-status-update";
        const payload = {
          requestedUsername: param1,
          requestAttackUrl: param2,
          status: param3,
        };

        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          payload
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
           await getRequestsforVendors();
            enqueueSnackbar("Successfully Status Changed.", {
              variant: "success",
            });
            setshowLoader(false);
          } else {
            enqueueSnackbar("Error in request.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
      }
    } catch (e) {
      enqueueSnackbar("Error in request.", { variant: "error" });
      setshowLoader(false);
    }
  };


  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Requests | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <NewHeader titleName="Vendor Requests"/>
        </Stack>

        <Card className="full-coverdesign">
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <div className="cover-table">
            <h3 className="vendor-head">All vendors requests</h3>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table checkboxSelection={false} className="table-vendor">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          _id,
                          username,
                          requestAttackUrl,
                          requestDate,
                          status,
                          userFileStatus
                        } = row;
                        const selectedUser =
                          selected.indexOf(requestAttackUrl) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            selected={selectedUser}
                          >
                          
                            <TableCell component="th" scope="row" align="center" >
    
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {requestAttackUrl}
                                </Typography>

                            </TableCell>

                            <TableCell align="center">{username}</TableCell>

                            <TableCell align="center">
                              {moment(new Date(requestDate * 1000)).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {/* <Label color={(status === 0 && 'error') || 'success'}>{(status === 0 && 'Awaiting') || 'Fulfilled'}</Label> */}
                              <Label color={status === 0 ? "error" : "success"}>
                                {status === 0 ? "Awaiting" : "Fulfilled"}
                              </Label>
                            </TableCell>

                            <TableCell align="center">
                              {/* <Label color={(status === 0 && 'error') || 'success'}>{(status === 0 && 'Awaiting') || 'Fulfilled'}</Label> */}
                              {/* <Label color={status === 0 ? "error" : "success"}>
                                {status === 0 ? "Awaiting" : "Fulfilled"}
                              </Label> */}
                              <Switch
                                checked={userFileStatus === 0 ? false : true}
                                onChange={(event) =>
                                  changeAttackRequestStatus(
                                    event,
                                    username,
                                    requestAttackUrl,
                                    userFileStatus === 0 ? 1 : 0
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Remove
        </MenuItem>
      </Popover>
    </>
  );
}
