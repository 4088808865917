import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { ForgetPasswordForm } from '../sections/auth/forgettPassword';
import fullDark from '../assets/full-dark.png';
import { useState } from 'react'
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgetPasswordPage() {
  const mdUp = useResponsive('up', 'md');
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "Dark");
  return (
    <>
      <Helmet>
        <title> Forget | Genesis Platform  </title>
      </Helmet>
      <img src={fullDark} className="logo-size absolute-logo-forget" alt="logo" />
      <div className={
        `login-screen bg-image-forgot ` + (theme == "Dark" ? "body-dark register-scroll" : "body-light register-scroll")
      }>
        <StyledRoot className='full-width-forgot'>
          {/* {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
             Forget Password
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )} */}
          <Container maxWidth="sm" className="box-login">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                <h2>Forgot password</h2>
                 <p>Enter your email address and we'll send you a link to reset your password.
                </p>
              </Typography>
              <ForgetPasswordForm />
            </StyledContent>
          </Container>
        </StyledRoot>
      </div>
    </>
  );
}
