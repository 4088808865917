import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import MailSend from "../assets/mailsend.svg";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import moment from "moment";
// components
import Iconify from "../components/iconify";
import ModalDialog from "./ModalDialog.js";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";

// ----------------------------------------------------------------------
export default function VendorAssessmentsPage() {
  const theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  // const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  // const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);
  // const [urlLinked, setUrlLinked] = useState(0);
  const [complianceData, setComplianceData] = useState({});
  // const [score, setScore] = useState(0);
  const userEmail = window.localStorage.getItem("userEmail");
  // const [vendorUrl, setVendorUrl]= useState(data.url);
  const [value, setValue] = React.useState("1");
  const [activeAssessment, setActiveAssessment] = useState("");

  useEffect(() => {
    getAssessmentByVendor();
  }, []);


  const getAssessmentByVendor = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const assessmentPayload = {
        email: userEmail,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_VENDOR}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            setComplianceData(obj);
            console.log(obj)
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const updateAssessmentStatus = async (assessment) => {    
    const payload = {
      assessment_id:assessment?._id,
      assessmentStatus: "Completed"
    };
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"update-assessment-status"}`,
          payload,
          {}
        )
          .then((res) => {
            enqueueSnackbar(res?.data.message, { variant: "success" });
            getAssessmentByVendor();
            setshowLoader(false); 
            
          })
          .catch((err) => {
            enqueueSnackbar(err?.message, { variant: "error" });
            setshowLoader(false);
          }); 
      } else {
        setshowLoader(false);
      
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }

  }

  
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const ModalContent = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      bgcolor: "background.paper",
      boxShadow: 24,
      textAlign: "center",
      borderRadius: "15px",
      p: 4,
    };
    return (
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img src={MailSend} alt="icon" />
          <h2 className="send-assessments">Send Assessment to the vendor?</h2>
        </Typography>
        <Grid item xs={12} md={12} lg={12} className="center-vendor">
          <Button
            variant="contained"
            className="click-to-begin m-l model-btns cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="click-to-begin model-btns"
            onClick={handleClose}
          >
            Send
          </Button>
        </Grid>
      </Box>
    );
  };

  return (
    
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
       <Container maxWidth={false}>
        <NewHeader titleName="Assessment" />
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : complianceData.length > 0 ? (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
                
                {complianceData.length > 0 ? (
                  <div className="Assessment-box">
                    {complianceData.map((key, val) => (
                      
                      <>                  
                        <Grid
                          container
                          columns={0}
                          columnSpacing={3}
                          className="box-Assessment"
                        >
                          <Grid item xs={12} md={2} lg={1}>
                            <div className="widthboxes">
                             <h5>Set</h5>
                              <p>{key.set}</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <div className="widthboxes">
                              <h5>Start Date</h5>
                              <p>
                                {moment(new Date(key.startDate * 1000)).format(
                                  "MMMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <div className="widthboxes">
                              <h5>Deadline</h5>
                              <p>
                                {moment(new Date(key.deadline * 1000)).format(
                                  "MMMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={1.5}>
                            <div className="widthboxes">
                              <h5>Progress</h5>
                              <p>{key.score}/100</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={1.5}>
                            <div className="widthboxes">
                              <h5>Created By</h5>
                              <p>{key.firstName}</p>
                            </div>
                          </Grid>
                         
                          {/* {(key.assessmentStatus === "Submitted" || key.assessmentStatus === "Engagement") &&  */}
                          {(key.assessmentStatus === "Submitted") ? (
                            <Grid item xs={12} md={2} lg={2}>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Button
                                  aria-label="center"
                                  variant="contained"
                                  className="addnew send-assessment"
                                  onClick={async (event) => {
                                    setActiveAssessment(key);
                                    updateAssessmentStatus(key);
                                  }}
                                >
                                  complete
                                </Button>  
                              </div>
                            </Grid>
                          ) : (
                            <Grid item xs={12} md={2} lg={2}>
                              <div className="widthboxes">
                                <h5>Status</h5>
                                <p>{key.assessmentStatus}</p>
                              </div>
                            </Grid>
                          )}

                          <Grid item xs={12} md={2} lg={1.2}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <Link to={`../questionnaireSubmitted/${key._id}`}>
                                <Button
                                  variant="contained"
                                  className="addnew send-assessment"
                                  style={{ minWidth: '80px', maxWidth: '100px' }} // Added maxWidth and minWidth
                                >
                                  Show
                                </Button>
                              </Link>
                            </div>
                          </Grid>
                      
                        </Grid>
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="Assessment-box">
                    <div className="manage-mid">
                      <h2>No Assessment Found!</h2>                     
                    </div>
                  </div>
                )}          
          </Box>
          
            
            
        ) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Assessment Found</h4>
              </Card>
            </Grid>
          </Box>
        )}
        <ModalDialog
          handleClose={handleClose}
          open={open}
          contentData={ModalContent}
        />
      </Container>
    </>
  );
}
