import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import "./css/Vendors.css";
import "./css/Attack.css";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  Grid,
  TextField,
} from "@mui/material";
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';

// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// mock
import USERLIST from "../_mock/user";
import PostRequest from "../components/apiConnections/postRequest";
import { SnackbarProvider, useSnackbar } from "notistack";
import Loader from "../Loader";
import ModalDialog from "./ModalDialog.js";
import Delete from "../assets/delete.svg";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false, filter: true, sort: true },
  // { id: "target", label: "Target", alignRight: false },
  // { id: "score", label: "Score", alignRight: false },
  { id: "Fulfilled", label: "Score", alignRight: false },
  { id: "View", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  textAlign: "center",
  borderRadius: "15px",
  p: 4,
};

export default function UserPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  let roleId = parseInt(window.localStorage.getItem('roleId'));
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);

  useEffect(()=>{
    setOpenUser((userRemoveId!==0) ? true : false);
  },[userRemoveId])

  useEffect(() => {
    setInterval(() => {
      const themeName =
        localStorage.getItem("theme") !== null
          ? localStorage.getItem("theme")
          : "Dark";
      setTheme(themeName);
    }, 3000);
  }, []);


  const handleOpen = (url) => {
    setUserRemoveId(url);
  };
  const handleClose = () => {
    setOpenUser(false)
    setUserRemoveId(0);
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  useEffect(() => {}, [vendorsData]);

  const getVendorDetails = async () => {
    // REACT_APP_VENDORS_LIST
    setshowLoader(true);
    // const role = parseInt(window.localStorage.getItem("roleId"));
    const endpoint = process.env.REACT_APP_GET_ALL_ATTACK_LIST

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setisNotFound(!(resVendors.data.data.length > 0));
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const handleRequestSort = (event, property) => {  
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vendorsData.map((n) => n.requestAttackUrl);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(vendorsData, getComparator(order, orderBy), filterName);
  const emptyRows =  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);
  const [newDomain, setNewDomain] = useState("");
  const inputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newDomain") {
      setNewDomain(value);
    }
  };

  const removeData = async (url)=>{
    const deleteId = userRemoveId;

    setshowLoader(true); 
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
     );
     if(refreshToken)
     {
    const endpoint = process.env.REACT_APP_DELETE_ATTACK;
    const payload = {
      requestAttackUrl : deleteId
    }
    await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,  
      payload      
     )
     .then((resVendors)=>{    
      enqueueSnackbar(resVendors.data.message, { variant : 'success' });    
      setshowLoader(false);   
      window.localStorage.removeItem('deleteUrl');
      handleClose();
      setTimeout (()=>{
        window.location.reload(true) 
        },1000)    
     })
     .catch((errVendor)=>{
      enqueueSnackbar(errVendor.message, { variant : 'error' });    
      setshowLoader(false);       
     })
    }


  };


  const formSubmit = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        let endPoint = process.env.REACT_APP_REQUEST_ATTACK;
        const payload = {
          requestUrl: newDomain,
        };

        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          payload
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar("Successfully Requested.", {
              variant: "success",
            });
            setshowLoader(false);
          } else {
            enqueueSnackbar("Error in request.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
        getVendorDetails();
        setModelOpen(false);
      }
    } catch (e) {
      enqueueSnackbar("Error in request.", { variant: "error" });
      setshowLoader(false);
      setModelOpen(false);
    }
  };


  const ModalContent = () => {  
    return (
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img src={Delete} alt="icon" />
          <h2 className="send-assessments">Are you sure want to remove assets ?</h2>
        </Typography>       
    );
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Vendors | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <NewHeader titleName="Attack Surface"/>
        <div>
          <Modal
            open={modelOpen}
            onClose={handleModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={
              theme == "Dark"
                ? "dark-attack-modal modal-popup"
                : "light-attack-modal modal-popup"
            }
          >
            <Box sx={style} className="black-bg">
              <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ClearIcon
                        className="cross-icon"
                        onClick={handleModelClose}
                      ></ClearIcon>

                      <FormControl sx={{ width: "100%" }}>
                        {/* <InputLabel htmlFor="my-input">Import File</InputLabel> */}
                        <form onSubmit={(e) => formSubmit(e)}>
                          <Box>
                            <Grid xs={12} md={12} lg={12} container>
                              <TextField
                                name="newDomain"
                                value={newDomain || ""}
                                placeholder="Enter domain name"
                                onChange={(e) => inputChange(e)}
                                required={true}
                                margin="normal"
                                helperText="Enter domain name!"
                                id="full-width-text-field"
                                // label="Domain"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            component="button"
                            type="submit"
                            className="save-file-btn"
                          >
                            Submit
                          </Button>
                        </form>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Modal>
        </div>

        <Card className="full-coverdesign">
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <div className="cover-table">
            <h3 className="vendor-head">Assets</h3>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table checkboxSelection={false} className="table-vendor">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                  {filteredUsers.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          id,
                          name,
                          score
                        } = row;
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            selected={selectedUser}
                          >
                            <TableCell align="center">
                              {name}
                            </TableCell>
                            <TableCell align="center">
                              <Label>
                               {score}
                              </Label>
                            </TableCell>
                            <TableCell align="center">  
                            <div className="icons-aligns">                          
                                <a
                                  href={`./attack-detail/${name}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    backgroundColor: "grey",
                                    padding: "3px 5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  View
                                </a>       
                          {(roleId === 2) ?
                        <a href="#" className="remove-color" onClick={() => handleOpen(name)}>Remove</a>                 
                       : ''
                         }        
                         </div>              
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          
                            <Typography variant="h6" paragraph>
                              No attack detail found
                            </Typography>                      
                        
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeData}
          contentData={ModalContent()}
        />
      </Container>
      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
       <ModalDialog
          handleClose={handleClose}
          open={open}
          contentData={ModalContent}
        /> 
      </Popover> */}
    </>
  );
}
