import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import MailSend from "../assets/mailsend.svg";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
  Modal,
  Checkbox
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import moment from "moment";
// components
import Iconify from "../components/iconify";
import ModalDialog from "./ModalDialog.js";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import QuestionCategoryInfo from './QuestionCategoryInfo';

// ----------------------------------------------------------------------
export default function VendorRemediationPage() {
  const navigate = useNavigate()
  const theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState(0);
  const [complianceData, setComplianceData] = useState({});
  const [score, setScore] = useState(0);
  const userEmail = window.localStorage.getItem("userEmail");
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [modelOpen, setModelOpen] = useState(null);
  const [checkDeadlineOver, setCheckDeadlineOver] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "solid #0000",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    height: "80vh",
    overflowY: "auto",
  };

  useEffect(() => {
    getRemediationByVendor();
  }, []);




  const getRemediationByVendor = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const remediationPayload = {
        email: userEmail,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_PLAN_BY_VENDOR}`,
          remediationPayload
        )
          .then((resRemediation) => {
            const obj = resRemediation.data.data;
            setComplianceData(obj);
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Remediation not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Remediation Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  function createData(category, issue, grade, score) {
    return { category, issue, grade, score };
  }
  const rows = [
    createData("Breaches", breaches, "-", breachesScore),
    createData("DNS", dns, "-", dnsScore),
    createData("Encryption", encryption, "-", encryptionScore),
    createData("Misconfiguration", misconfiguration, "-", misconfigrationScore),
    createData("Patching", patching, "-", patchingScore),

  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  }
  function issuespatchingList(name, category, severity, view, ele, cve) {
    return { name, category, severity, view, ele, cve };
  }

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAnswerChange = (optionData) => {
    if (modelOpen.que.optionType === 'MCQ') {
      // Handle checkbox (multiple choice) logic
      const newAnswers = modelOpen.ans.includes(optionData.title)
        ? modelOpen.ans.filter(answer => answer !== optionData.title)
        : [...modelOpen.ans, optionData.title];

      setModelOpen({ ...modelOpen, ans: newAnswers });
    } else {
      // Handle radio (single choice) logic
      setModelOpen({ ...modelOpen, ans: optionData.title });
    }
  };


  const ExpandableTableRow = ({
    children,
    refs,
    urls,
    cve,
    ele,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell padding="checkbox" />
            {expandComponent}

            {ele?.Email?.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>Affected Emails</b>
                </TableCell>
              </TableRow>
            ) : null}
            {typeof ele?.Email == "object" &&
              ele?.Email?.map((url, index) => (
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="2">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                  <TableCell colSpan="2">{ele.Source}</TableCell>
                </TableRow>
              ))}

            {refs?.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>References</b>
                </TableCell>
              </TableRow>
            ) : null}

            {refs?.map((url, index) => (
              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell colSpan="1">#{index + 1}</TableCell>

                <TableCell colSpan="4">
                  <a href={url} target="_blank">
                    {url}
                  </a>
                </TableCell>
              </TableRow>
            ))}
            {urls?.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>Affected URLs</b>
                </TableCell>
              </TableRow>
            ) : null}
            {urls?.map((url, index) =>
              index + 1 == urls?.length ? (
                <TableRow>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="4">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="4">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableRow>
        )}
      </>
    );
  };

  const ExpandableTableRowPatching = ({
    children,
    cve,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow sx={{ "& td": { borderBottom: 0 } }}>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };

  const ModalContent = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      bgcolor: "background.paper",
      boxShadow: 24,
      textAlign: "center",
      borderRadius: "15px",
      p: 4,
    };
    return (
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img src={MailSend} alt="icon" />
          <h2 className="send-assessments">Show Remediation?</h2>
        </Typography>
        <Grid item xs={12} md={12} lg={12} className="center-vendor">
          <Button
            variant="contained"
            className="click-to-begin m-l model-btns cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="click-to-begin model-btns"
            onClick={handleClose}
          >
            Show
          </Button>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <NewHeader titleName="Remediation" />
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : complianceData?.length > 0 ? (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
            {complianceData?.length > 0 ? (
              <div className="Assessment-box">
                {complianceData?.map((key, index) => (
                  <>

                    <Grid
                      container
                      columns={0}
                      columnSpacing={3}
                      className="box-Assessment"
                    >
                      <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                        <p><b>Q:</b> <span>{key?.remediationPlan?.que?.text ? key?.remediationPlan?.que?.text : 'N/A'}</span></p>
                      </Grid>


                      <Grid item xs={12} md={3} lg={3}>

                        <div className="widthboxes">
                          <h5>URL</h5>
                          <p>{key.url}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <div className="widthboxes">
                          <h5>Created Date</h5>
                          <p>
                            {moment(new Date(key.startDate * 1000)).format(
                              "MMMM Do YYYY"
                            )}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <div className="widthboxes">
                          <h5>Created By</h5>
                          <p>{key.createdBy}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <div className="fitbutton">
                          {/* <Link to={{ pathname: `../remediation-plan-data/${key.url}`, state: key.createrEmail }}> */}
                          {/* <Button
                            variant="contained"
                            className="addnew send-assessment"
                            onClick={() => navigate(`../remediation-plan-data/${key.url}`, { state: { createrEmail: key.createrEmail } })}
                          >
                            Show
                          </Button> */}
                          <Button
                            variant="contained"
                            className="addnew send-assessment"
                            onClick={() => setModelOpen(key.remediationPlan)}
                          >
                            Show
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </div>
            ) : (
              <div className="Assessment-box">
                <div className="manage-mid">
                  <h2>No Remediation Found!</h2>
                </div>
              </div>
            )}
          </Box>



        ) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Remediation Found</h4>
              </Card>
            </Grid>
          </Box>
        )}
        {/* <ModalDialog
          handleClose={handleClose}
          open={open}
          contentData={ModalContent}
        /> */}

        <Modal
          open={modelOpen !== null}
          onClose={() => setModelOpen(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={
            theme === "Dark"
              ? "dark-attack-modal modal-popup"
              : "light-attack-modal modal-popup"
          }
        >
          <Box sx={style} className="black-bg openai-response">
            <CloseIcon onClick={() => setModelOpen(null)}
              style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}
            />

            <form className='full-form' >

              <h3 className="relation" style={{ color: '#fff' }}><HandshakeOutlinedIcon /> Remediation
              </h3>

              <div>
              <div style={{
                  border: '1px solid #353434',
                  borderRadius: '10px',
                  padding: '10px'
               }}>

                  <div className='flex-preview-tab'>
                    <p className='preview-question-heading'>Q.  </p>
                    <span className='data-text-preview'>{modelOpen?.que?.text ? modelOpen?.que?.text : 'N/A'}</span>
                  </div>
                  <QuestionCategoryInfo
                    data={modelOpen?.que}
                    marginLeftTrue2={true}
                  />
                  {/* {modelOpen?.que?.options?.map((optionData, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginLeft: '48px' }}>
                        <span className='preview-question-heading'>
                          {(modelOpen?.que?.type || modelOpen?.que?.optionType) === 'MCQ' ? (
                            <Checkbox
                              checked={modelOpen?.ans.includes(optionData.title)}
                              onChange={() => handleAnswerChange(optionData)} // Handle checkbox changes
                            />
                          ) : (
                            <Radio
                              checked={modelOpen?.ans === optionData.title}
                              onChange={() => handleAnswerChange(optionData)} // Handle radio changes
                            />
                          )}
                                  <span className='data-text-preview'>{optionData.title}</span>
                        </span>
                
                      </div>
                    </div>
                  ))} */}
                </div>

                <ul>
                  <li>
                    <h4>Objective: </h4> {modelOpen?.remediationPlan?.objective}
                  </li>
                  <li>
                    <h4>Action Items:</h4>
                    {modelOpen?.remediationPlan?.actionItems?.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <h4>Actions:</h4>
                          <ul>
                            <li>
                              <span><b>Responsible:</b></span> {item.responsible}
                            </li>
                            <li>
                              <span><b>Duration:</b></span> {item.duration}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    ))}
                  </li>

                  <li>
                    <h4>Refrences:</h4>
                    {modelOpen?.remediationPlan?.references?.map((item, index) => (
                      <ul key={index}>
                        <li>
                          <li>
                            <span><b>Refrence {index}:</b></span> {item}
                          </li>
                        </li>
                      </ul>
                    ))}
                  </li>
                </ul>
              </div>

            </form>

          </Box>

        </Modal>

      </Container >
    </>
  );
}
