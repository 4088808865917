import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import GetRequest from "../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
export default function AiEvidence({ domain, assessmentId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const getAiEvidence = () => {
    setLoading(true);

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_EVIDENCE_MAPPING}?assessmentId=${assessmentId}&domain=${domain}`;

    GetRequest(url, {})
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        setData(response?.data?.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        className="executive-report-btn"
        disabled={loading}
        onClick={() => getAiEvidence()}
      >
        Generate AI Avidence
        {loading ? (
          <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
        ) : null}
      </Button>
    </>
  );
}
