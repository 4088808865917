import React from 'react';
import GaugeComponent from 'react-gauge-component';

function GaugeChart() {
  return (
    <div className='gauge-semicircle gauge-container' style={{ width: '500px', height: '200px' }}>
      <GaugeComponent
        type="semicircle" // Using a semicircular gauge
        arc={{
          colorArray: ['#FF0000', '#FF4D00', '#FD9B3D', '#45D7A2'],
          padding: 0.06, // Adjust padding for smoother arc transitions
          subArcs: [
            { limit: 25 },  // 25% of the total gauge
            { limit: 50 },  // Next 25%
            { limit: 75 },  // Next 25%
            { limit: 100 }, // Last 25%
          ],
          arcWidth: 0.8, // Adjust arc thickness
        }}
        
        pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }} // Similar to the circle in the image
        value={85} // Adjust value as needed
        label={{
          display: true,
          text: "45/100",
          style: {
            fontSize: '25px',
            fontWeight: 'bold',
            fill: '#ffffff',
            fontFamily: 'Mona-Sans',
          },
        }}
      />
      <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
        Last scanned 25 Jan
      </p>
    </div>
  );
}

export default GaugeChart;
