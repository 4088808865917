import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import './css/Vendors.css';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import PostRequest from "../components/apiConnections/postRequest";
import moment from 'moment';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import UploadIcon from '../assets/upload-icon.svg';
import UploadVendorJSON from '../components/modal/UploadVendorJSON';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import NewHeader from '../layouts/dashboard/header/NewHeader';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'requestUrl', label: 'URL', alignRight: false, filter: true, sort: true },
  { id: 'requestDate', label: 'Date Time', alignRight: false },
  { id: 'status', label: 'Fulfilled', alignRight: false },
  { id: "uploadJson", label: "Upload JSON", alignRight: false }
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RequestsPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [vendors, setVendors] = useState({});
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [showUploadJsonModal, setShowUploadJsonModal] = useState(false);
  const [activeUrl, setActiveUrl] = useState("")
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    getRequestsforVendors();
  }, []);

  const getRequestsforVendors = async () => {
    setshowLoader(true);
    const endpoint = process.env.REACT_APP_ALL_VENDORS_REQUESTS;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setisNotFound(!(resVendors.data.data.length > 0));
            arrResponse.sort((a, b) => (a.requestDate > b.requestDate) ? -1 : 1);
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: 'error' });
            setshowLoader(false);
          })
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);

    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const filteredUsers = applySortFilter(vendorsData, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Requests | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <NewHeader titleName="Vendor Requests"/>
        </Stack>

        <Card className='full-coverdesign'>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <div className='cover-table'>
            <h3 className='vendor-head'>All vendors requests</h3>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table checkboxSelection={false} className='table-vendor' >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, requestUrl, requestDate, status } = row;
                      const selectedUser = selected.indexOf(requestUrl) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>

                          <TableCell component="th" scope="row" align="center">
                            <Typography variant="subtitle2" noWrap>
                              {requestUrl}
                            </Typography>
                          </TableCell>



                          <TableCell align="center">{
                            moment(new Date(requestDate * 1000)).format("MMMM Do YYYY, h:mm:ss a")
                          }</TableCell>

                          <TableCell align="center" className='not-third'>
                            {/* <Label color={(status === 0 && 'error') || 'success'}>{(status === 0 && 'Awaiting') || 'Fulfilled'}</Label> */}
                            <Label color={(status === 0 ? 'error' : 'success')}>{status === 0 ? 'Awaiting' : 'Fulfilled'}</Label>

                          </TableCell>

                          <TableCell align="center">
                            <Button onClick={() => { setShowUploadJsonModal(true); setActiveUrl(requestUrl) }}><UploadFileIcon /></Button>
                            {/* <img src={UploadIcon}  className='cursor-pointer' /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Remove
        </MenuItem>
      </Popover>

      <UploadVendorJSON
        open={showUploadJsonModal}
        handleClose={() => setShowUploadJsonModal(false)}
        activeUrl={activeUrl}
        handleSuccess={() => { setShowUploadJsonModal(false); getRequestsforVendors() }}
      />
    </>
  );
}
