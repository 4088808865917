import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Delete from "../../assets/delete.svg";
import DeleteRequest from "../apiConnections/deleteRequest";
import PostRequest from "../apiConnections/postRequest";
import Loader from '../../Loader';

function DeleteQuestionnaire({ setId, open, handleClose, handleSuccess }) {
  const { enqueueSnackbar } = useSnackbar();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [showLoader, setShowLoader] = useState(false)

  const navigate = useNavigate();
  const deleteQuestionnaireSetAPI = async () => {
    setShowLoader(true)
    const payload = { setId: setId };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endpoint =
          localStorage.getItem("roleId") == 2
            ? process.env.REACT_APP_DELETE_ADMIN_CUSTOM_QUESTIONNAIRE_SET
            : process.env.REACT_APP_DELETE_USER_CUSTOM_QUESTIONNAIRE_SET;
        await DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((resChart) => {
            if (resChart.status == 200) {
              enqueueSnackbar("Questionnaire set deleted successfully", {
                variant: "success",
                autoHideDuration: 3000,
              });
              handleClose();
              setShowLoader(false)
              setTimeout(() => {
                handleSuccess();
              }, 3000);
            } else {
              setShowLoader(false)
              enqueueSnackbar(resChart?.data?.message, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          })
          .catch((errChart) => {
            setShowLoader(false)
            enqueueSnackbar(errChart.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          });
      } else {
        setShowLoader(false)
        enqueueSnackbar("Token error", { variant: "error" });
      }
    } catch (error) {
      setShowLoader(false)
      enqueueSnackbar(error.message || "Something went wrong", {
        variant: "error",
      });
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };
  return (
    <>
      <Loader show={showLoader} />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={
            theme == "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
          }
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <img src={Delete} alt="icon" />
              <h2 className="send-assessments">
                Are you sure you want to remove this Questionnaire Set ?
              </h2>
            </Typography>
            <Grid item xs={12} md={12} lg={12} className="center-vendor">
              <Button
                className="deletequestion-btn"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="deletequestion-btn"
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => deleteQuestionnaireSetAPI()}
              >
                Remove
              </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default DeleteQuestionnaire;
