import { TextareaAutosize } from "@mui/base";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  Box,
  Button,
  Modal,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SendPlane from "../../src/assets/send-plane-line.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import "./css/questionnaire.css";
import "./css/viewAnswerPage.css";
import ModalDialog from "./ModalDialog.js";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import QuestionCategoryInfo from "./QuestionCategoryInfo.jsx";
import AiEvidence from "./GenerateAIEvidence.js";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  height: "80vh",
  overflowY: "auto",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function QuestionnaireSubmitted() {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const dispatch = useDispatch();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([]);
  const [assessmentId, setAssessmentId] = useState(data.id);
  const [questionnaire, setQuestionnaire] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [selectCategory, setSelectCategory] = useState(0);
  const [answersToBeSubmit, setAnswersToBeSubmit] = useState();
  const [ques, setQues] = useState({});
  const [questionnaireSet, setQuestionnaireSet] = React.useState("");
  const [lastClickedIndex, setLastClickedIndex] = useState(null);
  const location = useLocation();
  const { email: receiverEmail, prevUrl: prevUrl } = location.state;
  const theme = useTheme();
  const [domain, setDomain] = useState();
  const [modelOpen, setModelOpen] = useState(null);


  useEffect(() => {
    getQuestionnaire();
  }, []);

  const handleSendMessage = async (content, index) => {
    const payload = {
      receiver: receiverEmail,
      message: content,
      time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_MESSAGES}`,
          payload
        )

          .then((res) => {
            setLastClickedIndex(index);
            enqueueSnackbar(`Message Sent!! Redirecting To Chat`, {
              variant: "success",
            });
            setshowLoader(true)
            setTimeout(() => {
              setshowLoader(false)
              setLastClickedIndex(null);
              navigate("/dashboard/clientChat", { state: { receiverEmail } });
            }, 1000);
            // const response = res.data.data;
            // setDomain(response.domain); // Extract and set the domain
          })
          .catch((err) => {
            console.error("Error in Post API call", err);
          });
      } else {
        console.log("Refresh token is not available");
        window.location.href = "/";
      }
    } catch (error) {
      console.error("General error", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const readRadioButton = (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].ans = ans;
    setAnswersToBeSubmit(answersData);
  };

  const questionnaireAnswerConfig = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    if (answersData[category][index].ans == "")
      answersData[category][index].ans = [ans];
    else {
      let answerArr = [];
      if (answersData[category][index].ans?.includes(ans)) {
        answerArr = answersData[category][index].ans?.filter((d) => d != ans);
        answersData[category][index].ans = answerArr;
      } else {
        answersData[category][index].ans.push(ans);
      }
    }
    setAnswersToBeSubmit({ ...answersData });
  };

  const readRemark = (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].remark = ans;
    setAnswersToBeSubmit(answersData);
  };

  const do_saved = async (type) => {
    setshowLoader(true);
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: type,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        if (resultData && resultData.status === 200) {
          enqueueSnackbar("Answer submitted successfully.", {
            variant: "success",
          });
          setshowLoader(false);
          navigate("/vendor/vendorAssessment", { replace: true });
        } else {
          enqueueSnackbar("Answer not submitted.", { variant: "error" });
          setshowLoader(false);
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Answer not submitted.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const getQuestionnaire = async () => {
    setshowLoader(true);
    const payload = {
      assessment_id: assessmentId,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpoint = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_VENDOR;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            setDomain(res.data.data.domain);

            if (
              response.setType == "custom" ||
              response.setType == "customAdmin"
            ) {
              response.data?.forEach((item, i) => {
                item.question?.forEach((question, j) => {
                  question.passed = 0;
                  if (question.que?.options) {
                    for (let [
                      index,
                      option,
                    ] of question.que?.options?.entries()) {
                      if (question.que?.optionType == "MCQ") {
                        if (
                          question.ans?.includes(`${index + 1}`) &&
                          option.score > 0
                        ) {
                          question.passed = 1;
                          break;
                        }
                      } else {
                        if (question.ans == option.title && option.score > 0) {
                          question.passed = 1;
                          break;
                        }
                      }
                    }
                  }
                });
              });
              setQuestions(response.data);
            } else {
              setQuestions(response.data);
            }
            setQues(response);
            setAnswered(response.answered);
            setSubmitted(response.submitted);
            let finalObject = {};

            if (parseInt(response.answered) === 0) {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  let objecttoAdd = {
                    que: itemque,
                    ans: "",
                    remark: "",
                  };
                  ansarray.push(objecttoAdd);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            } else {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  ansarray.push(itemque);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            }
            // setTimeout(()=>{
            setshowLoader(false);
            // }, 1000)
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar("Questionnaire details not find.", {
              variant: "error",
            });
            setshowLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Questionnaire details not find.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const ModalContent = () => {
    return "Save Assessment";
  };

  const handleCloseButton = () => {
    setModelOpen(null);
  };

  return (
    <>
      <Loader show={showLoader} />
      <Container maxWidth={false}>
        <Typography variant="h4">
          <span className="heading-main">Questionnaire</span>
        </Typography>
        <div className="tabs-bg">
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {questions?.map((element, index, array) => {
              <Tab label={"test"} {...a11yProps(`tab11`)} />;
            })}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {questions?.map((tab, index) => (
                <Tab
                  key={tab.category.toString() + index}
                  label={tab.category}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            {questions?.map((tab, index) => (
              <TabPanel key={index} value={value} index={index} className="tabpanel-full-size">
                <div className="question-part">
                  <div className="inline-parts">
                    <h3>{ques.set}</h3>
                    {submitted === 1 ? (
                      <AiEvidence domain={domain} assessmentId={assessmentId}></AiEvidence>
                    ) : null
                    }
                    <Button
                      variant="contained"
                      className="click-to-begin m-l cancel-btn"
                      onClick={() => {
                        dispatch(changeEcosystemTab("4"));
                        navigate(`../${prevUrl}`);
                      }}
                    >
                      Back
                    </Button>
                  </div>

                  {console.log("answed", answered)}
                  {answered === 1
                    ? tab.question.map((key, i) => (
                      <>
                        {key.que?.text ? (
                          <div>
                            <Grid container columns={0} columnSpacing={3}>
                              <Grid item xs={12} md={10} lg={10} columns={0}>
                                <p>{key.que.text}</p>
                              </Grid>
                              <Grid item xs={12} md={2} lg={2}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <IconButton
                                    variant="outlined"
                                    onClick={() =>
                                      handleSendMessage(key.que.text, i)
                                    }
                                    color={
                                      lastClickedIndex === i
                                        ? "success"
                                        : "primary"
                                    }
                                    title={
                                      !lastClickedIndex &&
                                      "Click To Send Message"
                                    }
                                  >
                                    {lastClickedIndex === i ? (
                                      <CheckCircleOutlineIcon />
                                    ) : (
                                      <Tooltip
                                        title="Send as Message to Chat"
                                        arrow
                                      >
                                        <button className="send-icon">
                                          <img src={SendPlane} alt="icon" />
                                        </button>
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                  { key.ans !== "" && <Button
                                    id={
                                      key.passed === 1
                                        ? "passed-color"
                                        : "failed-color"
                                    }
                                    variant="contained"
                                    size="small"
                                  >
                                    {key.passed === 1 ? "Passed" : "Failed"}
                                  </Button>}
                                </div>
                              </Grid>
                            </Grid>
                            {key.que?.optionType === "MCQ" ? (
                              <div className="checkbox-view-answer">
                                <FormControl>
                                  {key.que?.options?.map((option, j) => {
                                    return (
                                      <FormControlLabel
                                        value={j + 1}
                                        control={
                                          <Checkbox
                                            checked={key.ans?.includes(
                                              `${j + 1}`
                                            )}
                                          />
                                        }
                                        label={option.title}
                                        onChange={(e) =>
                                          questionnaireAnswerConfig(e, i)
                                        }
                                        disabled={submitted ? true : false}
                                      />
                                    );
                                  })}
                                </FormControl>
                              </div>
                            ) : (
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue={key.ans}
                                  >
                                    {key.que?.options?.map((option, j) => {
                                      return (
                                        <FormControlLabel
                                          value={option.title}
                                          control={<Radio />}
                                          label={option.title}
                                          // onChange={(e) =>
                                          //   readRadioButton(e, i)
                                          // }
                                          disabled={true}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="view-file">Write here</p>
                              <div>
                                <Button onClick={() => setModelOpen(key.aiEvidence)}>View-Avidence</Button>
                              </div>

                              {answered === 1 ? (
                                key.fileName !== "" ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_BACKEND_BASE_URL +
                                      "/evidence/" +
                                      key.fileName
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="right-view"
                                  >
                                    <PreviewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }} />

                                  </a>
                                ) : null
                              ) : null}
                            </Box>
                            <TextareaAutosize
                              aria-label="Write here"
                              minRows={3}
                              className="w100-textarea"
                              defaultValue={key.remark || ""}
                              readOnly={submitted}
                              onChange={(e) => readRemark(e, i)}
                            />
                          </div>
                        ) : (
                          <div>
                            <Grid container columns={0} columnSpacing={3}>
                              <Grid item xs={12} md={10} lg={10} columns={0}>
                                <p>{key.que}</p>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={2}
                                lg={2}
                                className="d-flexalign"
                              >
                                <IconButton
                                  variant="outlined"
                                  onClick={() =>
                                    handleSendMessage(key.que, i)
                                  }
                                  color={
                                    lastClickedIndex === i
                                      ? "success"
                                      : "primary"
                                  }
                                  title={
                                    !lastClickedIndex &&
                                    "Click To Send Message"
                                  }
                                >
                                  {lastClickedIndex === i ? (
                                    <CheckCircleOutlineIcon />
                                  ) : (
                                    <Tooltip
                                      title="Send as Message to Chat"
                                      arrow
                                    >
                                      <button className="send-icon">
                                        <img src={SendPlane} alt="icon" />
                                      </button>
                                    </Tooltip>
                                  )}
                                </IconButton>
                                {key.ans!=="" && <Button
                                  id={
                                    key.ans === "Yes"
                                      ? "passed-color"
                                      : "failed-color"
                                  }
                                  variant="contained"
                                  size="small"
                                >
                                  {key.ans === "Yes" ? "Passed" : "Failed"}
                                </Button>}
                              </Grid>
                            </Grid>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={key.ans}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={submitted ? true : false}
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={submitted ? true : false}
                                />
                                <FormControlLabel
                                  value="N/A"
                                  control={<Radio />}
                                  label="N/A"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={submitted ? true : false}
                                />
                              </RadioGroup>
                              {answered === 1 ? (
                                key.fileName !== "" ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_BACKEND_BASE_URL +
                                      "/evidence/" +
                                      key.fileName
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="right-view"
                                  >
                                    <PreviewIcon style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }} />
                                  </a>
                                ) : null
                              ) : null}
                            </FormControl>
                            <p className="view-file">Write here</p>
                            <TextareaAutosize
                              aria-label="Write here"
                              minRows={3}
                              className="w100-textarea"
                              defaultValue={key.remark || ""}
                              readOnly={submitted}
                              onChange={(e) => readRemark(e, i)}
                            />
                          </div>
                        )}
                      </>
                    ))
                    : tab.question.map((key, i) => (
                      <>
                        {ques.setType === "custom" ||
                          ques.setType === "customAdmin" ? (
                          <div>
                            <Grid container columns={0} columnSpacing={3}>
                              <Grid item xs={12} md={10} lg={10} columns={0}>
                                <p>{key.text}</p>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={2}
                                lg={2}
                                className="d-flexalign"
                              >
                                <IconButton
                                  variant="outlined"
                                  onClick={() =>
                                    handleSendMessage(key.text, i)
                                  }
                                  color={
                                    lastClickedIndex === i
                                      ? "success"
                                      : "primary"
                                  }
                                  title={
                                    !lastClickedIndex &&
                                    "Click To Send Message"
                                  }
                                >
                                  {lastClickedIndex === i ? (
                                    <CheckCircleOutlineIcon />
                                  ) : (
                                    <Tooltip
                                      title="Send as Message to Chat"
                                      arrow
                                    >
                                      <button className="send-icon">
                                        <img src={SendPlane} alt="icon" />
                                      </button>
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </Grid>
                            </Grid>
                            <QuestionCategoryInfo data={key} />
                            {key.optionType === "MCQ" ? (
                              <div>
                                <FormControl>
                                  {key.options?.map((option, j) => {
                                    return (
                                      <FormControlLabel
                                        value={j + 1}
                                        control={<Checkbox />}
                                        label={option.title}
                                        onChange={(e) =>
                                          questionnaireAnswerConfig(e, i)
                                        }
                                        disabled={true}
                                      />
                                    );
                                  })}
                                </FormControl>
                              </div>
                            ) : (
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    {key.options?.map((option, j) => {
                                      return (
                                        <FormControlLabel
                                          value={option.title}
                                          control={<Radio />}
                                          label={option.title}
                                          onChange={(e) =>
                                            readRadioButton(e, i)
                                          }
                                          disabled
                                          readOnly
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <Grid container columns={0} columnSpacing={3}>
                              <Grid item xs={12} md={10} lg={10} columns={0}>
                                <p>{key}</p>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={2}
                                lg={2}
                                className="d-flexalign"
                              >
                                <IconButton
                                  variant="outlined"
                                  onClick={() => handleSendMessage(key, i)}
                                  color={
                                    lastClickedIndex === i
                                      ? "success"
                                      : "primary"
                                  }
                                  title={
                                    !lastClickedIndex &&
                                    "Click To Send Message"
                                  }
                                >
                                  {lastClickedIndex === i ? (
                                    <CheckCircleOutlineIcon />
                                  ) : (
                                    <Tooltip
                                      title="Send as Message to Chat"
                                      arrow
                                    >
                                      <button className="send-icon">
                                        <img src={SendPlane} alt="icon" />
                                      </button>
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </Grid>

                            </Grid>
                            <QuestionCategoryInfo data={key} />
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled
                                  readOnly
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled
                                  readOnly
                                />
                                <FormControlLabel
                                  value="N/A"
                                  control={<Radio />}
                                  label="N/A"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled
                                  readOnly
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}

                        <p>Write here</p>
                        <TextareaAutosize
                          aria-label="Write here"
                          minRows={3}
                          className="w100-textarea"
                          onChange={(e) => readRemark(e, i)}
                          disabled
                          readOnly
                        />
                      </>
                    ))}

                  {/* {
              tab.question.map((key, val)=>(
                <>
                <p>{key}</p>
                <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </FormControl>
              <p>Write here</p>
              <TextareaAutosize
                aria-label="Write here"
                minRows={3}
                className="w100-textarea"
              />
              </>
              ))
            } */}
                </div>
              </TabPanel>
            ))}
          </Box>
          <Grid item xs={12} md={12} lg={12} className="btn-right">
            <>
              {/* <Button
                    variant="contained"
                    className="click-to-begin m-l cancel-btn"
                    onClick={()=>do_saved(0)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    className="click-to-begin"
                    onClick={handleOpen}
                  >
                    Submit
                  </Button> */}
            </>
          </Grid>
        </div>
        <ModalDialog
          handleClose={handleClose}
          open={open}
          onSubmit={() => do_saved(1)}
          contentData={ModalContent}
        />

        <Modal
          open={modelOpen !== null}
          onClose={() => setModelOpen(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={
            theme === 'Dark'
              ? 'dark-attack-modal modal-popup'
              : 'light-attack-modal modal-popup'
          }
        >

          <Box sx={style} className="black-bg openai-response">
          <CloseIcon
                        onClick={handleCloseButton}
                        style={{ position: 'absolute', right: 15, top: 15, cursor: 'pointer' }}
                    />
            <h2>Avidence by Ai</h2>
            <div>
              <div variant="body2">
                <b>Vendor Response:</b><br/> 
                {modelOpen?.vendorResponse ? modelOpen?.vendorResponse : 'N/A'}
              </div>
              <div variant="body2" style={{marginTop:'1rem'}}>
                <b>Identified Evidence:</b><br/> 
                 {modelOpen?.identifiedEvidence ? modelOpen?.identifiedEvidence : 'N/A'}
              </div>
              <div variant="body2" style={{marginTop:'1rem'}}>
                <b>Recommended Action:</b><br/> 
                 {modelOpen?.recommendedAction ? modelOpen?.recommendedAction : 'N/A'}
              </div>
              <div variant="body2" style={{marginTop:'1rem'}}>
                <b>Document Referenced:</b><br/> 
                {modelOpen?.documentReferenced ? modelOpen?.documentReferenced : 'N/A'}
              </div>
            </div>
          </Box>
        </Modal>

      </Container>
    </>
  );
}

