import React from 'react'
import PlusVendor from '../../../assets/plus-vendor.svg';
import HamburgerMenu from "../../../pages/HamburgerMenu.js";
import { Typography } from '@mui/material';
import AccountPopover from './AccountPopover';
import { useState } from 'react';
import DomainSearch from '../../../components/addVendorByClient/domainSearch';
import GlobalSearchBar from '../../../pages/globalSearchBar.js';
function NewHeader({ titleName }) {
   const [showAddButton, setShowAddButton] = useState((localStorage.getItem("roleId") === "1") ? true : false);
   const [modelOpen, setModelOpen] = useState(false);
   const handleModelOpen = () => setModelOpen(true);
   const handleModelClose = () => setModelOpen(false);
   return (
      <>
         <div className='newheader'>
            <Typography variant="h4" sx={{ mt: 0, mb: 0 }}>
               <span className="ml25 new-heading-front">
                  <HamburgerMenu />
                  <span>{titleName}</span></span>
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
               {showAddButton && <div className='add-vendor-button-header' onClick={handleModelOpen}>
                  <img src={PlusVendor} alt='icon' /> Add Vendor
               </div>}
               <GlobalSearchBar />
               <AccountPopover />
            </div>
         </div>
         <DomainSearch
            open={modelOpen}
            handleClose={handleModelClose} />
      </>
   )
}

export default NewHeader