import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Link,
  Grid,
  CardHeader,
} from "@mui/material";
import "../../pages/css/AddFile.css";
import "../../pages/css/complianceCard.css";
import "../../pages/css/uploadQuestionnaire.css";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import PostRequest from "../apiConnections/postRequest";
import { useSnackbar } from "notistack";
import Loader from "../../Loader";
import { includes } from "lodash";
import CrossPop from "../../assets/pop-cross.svg";
import UploadIMG from "../../assets/image-upload-image.svg";
import DownloadIon from "../../assets/download-icon.svg";


function UploadQuestionnaireSet({ open, handleClose, handleSuccess, onClose }) {
  const theme = localStorage.getItem("theme");
  const roleId = localStorage.getItem("roleId");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fileData, setFileData] = useState();
  const [logo, setLogo] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadError, setUploadError] = useState("");

  const handleFileChange = (files, type) => {
    // setShowLoader(true);
    setUploadError("");
    if (type == "document") {
      let file = files.files[0];
      if (!file) return null;
      const checkImage = file;
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (fileExtension === "xlsx") {
        if (fileSize <= 20000) {
          setFileData(checkImage);
          // setShowLoader(false);
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          // setShowLoader(false);
        }
      } else {
        setUploadError("*Please upload excel file only.");
        enqueueSnackbar("Please upload excel file only.", {
          variant: "error",
        });
        setFileData(null);
        // setShowLoader(false);
      }
    } else {
      let file = files.files[0];
      if (!file) return null;
      const checkImage = file;
      console.log(checkImage);
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      if (
        // ["jpg", "png", "jpeg"].includes(fileExtension)
        checkImage.type?.includes("image")
      ) {
        console.log("e");
        setLogo(checkImage);
        // setShowLoader(false);
      } else {
        enqueueSnackbar("Please upload image file only", { variant: "error" });
        setLogo(null);
        // setShowLoader(false);
      }
    }
  };

  const do_upload = async (e) => {
    e.preventDefault();
    if (!fileData) {
      enqueueSnackbar("Please upload valid file", { variant: "error" });
      return null;
    }
    setShowLoader(true);
    setDisableUploadButton(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endPoint = "";
        if (roleId == 1)
          endPoint =
            process.env.REACT_APP_UPLOAD_USER_QUESTIONNAIRE_SET_BY_EXCEL;
        else
          endPoint =
            process.env.REACT_APP_UPLOAD_ADMIN_QUESTIONNAIRE_SET_BY_EXCEL;
        let formData = new FormData();
        formData.append("logo", logo);
        formData.append("file", fileData);
        formData.append("email", localStorage.getItem("userEmail"));
        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          formData,
          "ImageUpload"
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            console.log("else 1");
            enqueueSnackbar(uploadJson.data?.message, { variant: "success" });
            setShowLoader(false);
            setTimeout(() => {
              setDisableUploadButton(false);
            }, 3000);
            setFileData();
            handleSuccess();
          } else {
            console.log("else 2");

            setFileData(null);
            if (uploadJson.data?.message) {
              enqueueSnackbar(`${uploadJson.data.message}`, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else enqueueSnackbar("File not uploaded", { variant: "error" });
            setShowLoader(false);
            setTimeout(() => {
              setDisableUploadButton(false);
            }, 2000);
          }
        } else {
          console.log("else 3");
          if (uploadJson.data?.message) {
            setFileData(null);
            enqueueSnackbar(`${uploadJson.data.message}`, {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          } else enqueueSnackbar("File not uploaded", { variant: "error" });
          setShowLoader(false);
          setTimeout(() => {
            setDisableUploadButton(false);
          }, 2000);
        }
      }
    } catch (e) {
      console.log("catch");
      setFileData(null);
      if (e.message)
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
          // preventDuplicate: true,
          onClick: () => {
            closeSnackbar();
          },
        });
      else enqueueSnackbar("Error in upload file.", { variant: "error" });
      setShowLoader(false);
      setTimeout(() => {
        setDisableUploadButton(false);
      }, 3000);
    }
  };

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer;
    if (droppedFile.length > 0) {
      handleFileChange(
        droppedFile,
        fileType === "document" ? "document" : "logo"
      );
    } else {
      enqueueSnackbar("Please upload only one file", { variant: "error" });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <>
      <Loader show={showLoader} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${open ? "blur-background" : ""}`}
      >
        <div
          className={theme === "Light" ? "light-mode-class addfile" : "addfile"}
        >
          <Box
            className="preview-popup upload-questionnaire-pop"
            sx={{ bgcolor: "background.paper" }}
          >
            {/* <Button onClick={onClose} className='cross-right-side'>
                            <CloseIcon />
                        </Button> */}
            <div className="add-new-attack-heading">
              <p>Upload a Questionnaire</p>
              <img
                onClick={onClose}
                src={CrossPop}
                style={{ cursor: "pointer" }}
                alt="icon"
              />
            </div>
            <div
              className="bgaddfiles file-upload-modal"
              style={{ border: "none"}}
            >
              <Typography
                variant="h4"
                gutterBottom
                className="centerpoint upload-questionnaire"
              >
                {/* <Box sx={{ textAlign: "start" }} className="upload-instruction">
                               <div>Instructions for Uploading the Questionnaire Excel File</div>
                                    <ol>
                                        <li>
                                            <div className='desc'>Download the example template to guide the correct formatting of your Excel file.</div>
                                            <Link href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-questionnaire.xlsx`}
                                                download="demo-questionnaire.xlsx" underline="none">
                                                Download Template
                                            </Link>
                                        </li>
                                        <li>
                                            <div className='desc'>Some columns in your Excel file must contain specific, pre-defined values. You should use only the options provided in the reference file.</div>
                                            <Link href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/Questionnaire-values.xlsx`}
                                                download="Questionnaire-values.xlsx" underline="none">
                                                Download Value Reference
                                            </Link>
                                        </li>
                                        <li>
                                            <div className='desc'>Once you've formatted your Excel file according to the template and ensured all required values are correct, you’re ready to upload.</div>
                                        </li>
                                    </ol>
                                </Box> */}
                {/* <Typography sx={{ marginTop: "10px", fontSize: "16px !important" }}>
                                    Value for Risk Category, Issue Name, Vulnerability Type, ISO 27001 Control, GDPR, NIST,
                                    Risk Mapping, Impact on Vendor, Impact on Client columns should be one of the possible value.
                                    you can download above file to find possible values
                                </Typography> */}
                <Box sx={{ color: "red" }}>{uploadError || null}</Box>
                {/* Logo */}
                <div className="download-demo-btn">
                  <p>Please upload excel file in demo file format only.
                  </p>
                  <Button
                  sx={{ marginRight: "0px" }}
                  variant="contained"
                  onClick={() => {
                    const fileUrl =`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-questionnaire.xlsx`;
                    window.location.href = fileUrl;
                  }}
                  className="add-questionnaire-button underline-text">
                    <img src={DownloadIon} alt="icon" style={{marginRight:'13px'}}/>  Download demo file
                  </Button>
                  <Button
                  sx={{ marginLeft: "8px" }}
                  variant="contained"
                  onClick={() => {
                    const fileUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/assets/Questionnaire-values.xlsx`;
                    window.location.href = fileUrl;
                  }}
                  className="add-questionnaire-button underline-text">
                    <img src={DownloadIon} alt="icon" style={{marginRight:'13px'}}/>  Download Mapping file
                  </Button>
                </div>
                <div className="pop-upload-q">
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="logo-preview"
                    onDrop={(e) => handleDrop(e, "logo")}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    {/* <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} /> */}
                    <Button
                      htmlFor="logo-upload"
                      size="small"
                      variant="contained"
                      color="primary"
                      component="label"
                      className={`w-100 ${
                        isDragging ? "drag-over" : ""
                      }`}
                      style={{ with: "100%" }}
                      title="Select Image"
                    >
                      {logo ? (
                        <div>
                          <img
                            className="preview-image"
                            src={
                              typeof logo == "string"
                                ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logo}`
                                : URL.createObjectURL(logo)
                            }
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <>
                         <div className="upload-img-border">
                            <img src={UploadIMG} />
                             <span style={{ color: "#fff" }}>
                              Drag and drop logo files to upload
                             </span>
                             <button className="selectfiles">Select Files</button>                             
                          </div>
                        </>
                      )}
                    </Button>
                    <input
                      name="logo"
                      id="logo-upload"
                      type="file"
                      accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                      style={{ display: "none", with: "100%" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleFileChange(e.target, "logo");
                      }}
                    />
                  </Grid>
                  {/* Document */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="document-preview"
                    onDrop={(e) => handleDrop(e, "document")}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    {/* <CardHeader title={"Upload Excel File"} sx={{ pl: 0 }} /> */}
                    <Button
                      htmlFor="file-upload"
                      size="small"
                      variant="contained"
                      color="primary"
                      component="label"
                      className={`w-100 ${
                        isDragging ? "drag-over" : ""
                      }`}
                      style={{ with: "100%" }}
                      title="Select File"
                    >
                      {fileData ? (
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {typeof fileData == "string"
                            ? fileData
                            : fileData?.name}
                        </div>
                      ) : (
                        <>
                          <div className="upload-img-border">
                            <img src={UploadIMG} />
                             <span style={{ color: "#fff" }}>
                              Drag and drop logo files to upload
                             </span>
                             <button className="selectfiles">Select Files</button>                             
                          </div>
                        </>
                      )}
                    </Button>
                    <input
                      name="document"
                      id="file-upload"
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none", width: "100%" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleFileChange(e.target, "document");
                        e.target.value = null;
                      }}
                    />
                  </Grid>
                </div>
                <LoadingButton
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  color="success"
                  className="save-file-btn"
                  sx={{ width: "auto" }}
                  onClick={(e) => do_upload(e)}
                  disabled={disableUploadButton}
                >
                  Save
                </LoadingButton>
              </Typography>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default UploadQuestionnaireSet;
