import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Attack.css";
import "./css/Vendors.css";
import CrossPop from '../assets/pop-cross.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import PlusVendor from '../assets/plus-vendor.svg';
import SearchIcon from '../assets/searchico.svg';
import defaultLogo from '../assets/companyLogo.jpg';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  // CircularProgress,
  Select,
  TableHead,
  IconButton, Menu, MenuItem, InputAdornment
} from "@mui/material";
// mock
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import ModalDialog from "./ModalDialog.js";
import { useNavigate } from "react-router-dom";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
// import AttackSurfacedataTable from "./AttackSurfacedataTable.js";
// ----------------------------------------------------------------------
// Menu component for the three-dot menu
function RowMenu({ requestAttackUrl, openDeleteModal, status }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)} // Removed the extra closing parenthesis here
        onClose={handleClose}
        className='rowmenu'
      >
        {status ? <MenuItem className='border-bot-line' onClick={() => navigate(`/dashboard/attack-detail/${requestAttackUrl}`)}>
          <img src={EyeMenu} alt="View" /> View
        </MenuItem> : null}
        <MenuItem onClick={openDeleteModal}>
          <img src={Trash} alt="Delete" /> Remove
        </MenuItem>
      </Menu>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  // border: '2px solid #000',
  boxShadow: 24,
  textAlign: "center",
  borderRadius: "15px",
  p: 4,
};

export default function UserPage() {
  // const StyledGridOverlay = styled("div")(({ theme }) => ({
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100%",
  //   "& .ant-empty-img-1": {
  //     fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  //   },
  //   "& .ant-empty-img-2": {
  //     fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  //   },
  //   "& .ant-empty-img-3": {
  //     fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  //   },
  //   "& .ant-empty-img-4": {
  //     fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  //   },
  //   "& .ant-empty-img-5": {
  //     fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
  //     fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  //   },
  // }));
  // function CustomNoRowsOverlay() {
  //   return (
  //     <StyledGridOverlay>
  //       <svg
  //         width="120"
  //         height="100"
  //         viewBox="0 0 184 152"
  //         aria-hidden
  //         focusable="false"
  //       >
  //         <g fill="none" fillRule="evenodd">
  //           <g transform="translate(24 31.67)">
  //             <ellipse
  //               className="ant-empty-img-5"
  //               cx="67.797"
  //               cy="106.89"
  //               rx="67.797"
  //               ry="12.668"
  //             />
  //             <path
  //               className="ant-empty-img-1"
  //               d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
  //             />
  //             <path
  //               className="ant-empty-img-2"
  //               d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
  //             />
  //             <path
  //               className="ant-empty-img-3"
  //               d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
  //             />
  //           </g>
  //           <path
  //             className="ant-empty-img-3"
  //             d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
  //           />
  //           <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
  //             <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
  //             <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
  //           </g>
  //         </g>
  //       </svg>
  //       <Box sx={{ mt: 1 }}>No Attack Details Found</Box>
  //     </StyledGridOverlay>
  //   );
  // }
  const username = window.localStorage.getItem("userEmail")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);
  const [search, setSearch] = useState(null)
  const [totalAttack, setTotalAttack] = useState(0)
  useEffect(() => {
    setInterval(() => {
      const themeName =
        localStorage.getItem("theme") !== null
          ? localStorage.getItem("theme")
          : "Dark";
      setTheme(themeName);
    }, 2000);
  }, []);

  const handleClose = () => {
    setOpenUser(false);
    setUserRemoveId(0);
  };

  useEffect(() => {
    getAttackDetails(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (search !== null){
        getAttackDetails(1, rowsPerPage)
        setPage(1)
      } 
    }, 500)
    return () => clearTimeout(time)
  }, [search])

  const getAttackDetails = async (page1, rowsPerPage1) => {
    setshowLoader(true);
    const endpoint = "get-all-attack-request-by-id";
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?page=${page1}&limit=${rowsPerPage1}&search=${search || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            console.log(arrResponse)
            setisNotFound(!(arrResponse.length > 0));
            setVendorsData(arrResponse?.data || []);
            setTotalAttack(arrResponse?.total || 0)
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeLink = async () => {
    const deleteId = userRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "delete-attack-by-user";
      const payload = {
        username,
        requestAttackUrl: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getAttackDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    console.log("page", newPage)
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);
  const [newDomain, setNewDomain] = useState("");
  const inputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newDomain") {
      setNewDomain(value);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    let regex = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
    );
    if (!regex.test(newDomain)) {
      enqueueSnackbar("Error in domain name.", { variant: "error" });
      setshowLoader(false);
      return false;
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        let endPoint = process.env.REACT_APP_REQUEST_ATTACK;
        const payload = {
          requestUrl: newDomain,
          logo: `https://logo.clearbit.com/${newDomain}`
        };

        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          payload
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar("Successfully Requested.", {
              variant: "success",
            });
            setshowLoader(false);
          } else {
            enqueueSnackbar("Error in request.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
        getAttackDetails();
        setModelOpen(false);
      }
    } catch (e) {
      enqueueSnackbar("Error in request.", { variant: "error" });
      setshowLoader(false);
      setModelOpen(false);
    }
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments">
          Are you sure you want to remove assets ?
        </h3>
      </Typography>
    );
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Attacks | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="common-padding-manage">
        <NewHeader titleName="Attack Surface"/>
        <div>
          <Modal
            open={modelOpen}
            onClose={handleModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={
              theme == "Dark"
                ? "dark-attack-modal modal-popup"
                : "light-attack-modal modal-popup"
            }
          >
            <Box sx={style} className="black-bg">
              <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="add-new-attack-heading">
                        <p>Add New</p>
                        <img onClick={handleModelClose} src={CrossPop} style={{ cursor: 'pointer' }} alt="icon" />
                      </div>
                      <FormControl className="attack-field" sx={{ width: "100%" }}>
                        <form onSubmit={(e) => formSubmit(e)}>
                          <Box>
                            <label>Enter domain name</label>
                            <Grid xs={12} md={12} lg={12} container>
                              <TextField
                                name="newDomain"
                                value={newDomain || ""}
                                placeholder="xyz.com"
                                onChange={(e) => inputChange(e)}
                                required={true}
                                margin="normal"
                                // helperText="Enter domain name!"
                                id="full-width-text-field"
                                // label="Domain"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            component="button"
                            type="submit"
                            className="submit-domain-btn"
                          >
                            Submit
                          </Button>
                        </form>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Modal>
        </div>

        <Card className="attack-surface-data">
          <div className="cover-table">
            <div style={{ marginBottom: '25px', marginLeft: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', }}>
                <FormControl className='select-entries' variant="outlined" size="small">
                  <Select
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <span className='entries-per'>entries per page</span>
              </div>

              <div className='search-add-new'>
                <div className='add-new-attack' onClick={handleModelOpen}>
                  <img src={PlusVendor} alt='icon' /> Add New
                </div>
                <TextField
                  variant="outlined"
                  placeholder="Search…"
                  // value={searchText}
                  onChange={(e) => setSearch(e.target.value)}
                  className='searh-text-field attack-surface-search'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <TableContainer component={Paper}>
              <Table style={{ width: "100%;" }}>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell className="equal-width pl-40">#</TableCell>
                    <TableCell className="equal-width">Name</TableCell>
                    <TableCell className="equal-width">Fulfilled</TableCell>
                    <TableCell className="equal-width row-menu"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(vendorsData) && vendorsData
                    .map((row, i) => {
                      const { _id, requestAttackUrl, userFileStatus, logo } = row;                    
                      return (
                        <TableRow key={i}>
                          <TableCell className="equal-width pl-40">{((page - 1) * (rowsPerPage)) + (i + 1)}</TableCell>
                          <TableCell className="equal-width">
                            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                              <span className="logo-image-vendor">
                                <img className="attack-surface-list-logo" src={logo || ""} alt="Figma" onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = defaultLogo;
                                }} />
                              </span>
                              <span>{requestAttackUrl}</span>
                            </div>
                          </TableCell>
                          <TableCell className="equal-width">
                            <span className="onboard-green"> {userFileStatus === 0
                              ? "Scanning"
                              : "Scanned"}</span>
                          </TableCell>
                          <TableCell className="equal-width row-menu" onClick={() => setUserRemoveId(_id)} >
                            <RowMenu requestAttackUrl={requestAttackUrl} openDeleteModal={() => setOpenUser(true)} status={userFileStatus} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalAttack || 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </Card>
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeLink}
          contentData={ModalContent()}
        />
      </Container>
    </>
  );
}
