import DeleteIcon from "@mui/icons-material/Delete";
import SecurityIcon from "@mui/icons-material/Security";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, styled, Typography, CircularProgress, IconButton, MenuItem, Menu } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Delete from "../assets/delete.svg";
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import SendAssessmentModal from "../components/modal/sendAssessment";
import ShowAssessmentModal from "../components/modal/showAssessment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./css/assessment.css";
import ModalDialog from "./ModalDialog";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExtendDeadlineModel from "./models/ExtendDeadlineModel";
import ApproveDeclineAssesmentModel from "./models/ApproveDeclineAssesmentModel";
import NewHeader from "../layouts/dashboard/header/NewHeader";

export const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

export function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Assessment Data Found</Box>
    </StyledGridOverlay>
  );
}
function DataGridLoader() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px", overflow: "hidden" }}>
      <CircularProgress />
    </Box>
  )
}
export default function Assessment() {
  const { enqueueSnackbar } = useSnackbar();
  const [assessments, setAssessments] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] =
    useState(false);
  const [showAssessmentDetailModal, setShowAssessmentDetailModal] =
    useState(false);
  const [showExtendsDeadlineModal, setShowExtendsDeadlineModal] =
    useState(false);
    const [showAcceptDeclineModal, setShowAcceptDeclineModal] =
    useState(false);  
  const [activeAssessment, setActiveAssessment] = useState({});
  const [sendAssessmentModal, setSendAssessmentModal] = useState(false);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const [deadline, setDeadline] = useState('');
  const [extendDeadline, setExtendDeadline] = useState('');

  const [assessmentApprovedDeclinedStatus, setAssessmentApprovedDeclinedStatus ] = useState('');

  const [columns, setColumns] = useState([
    { field: "uid", headerName: "No.", editable: false, maxWidth: 60 },
    { field: "email", headerName: "Email Id", editable: false, minWidth: 230, headerAlign: 'left', align: 'left', flex: 1 },
    { field: "set", headerName: "Set", editable: false, minWidth: 50, headerAlign: 'left', align: 'left', flex: 1 },
    { field: "url", headerName: "URL", editable: false, minWidth: 150, headerAlign: 'left', align: 'left', flex: 1 },
    {
      field: "lastDate",
      headerName: "Deadline",
      editable: false,
      sortable: true,
      minWidth: 130,
      headerAlign: 'center', align: 'center', flex: 1,
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   editable: false,
    //   minWidth: 100,
    //   headerAlign: 'center', align: 'center', flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           minWidth: "100px",
    //           height: "28px",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           fontSize: "12px",
    //           fontWeight: "500",
    //           color: "white",
    //           borderRadius: "5px",
    //           paddingY: "2px",
    //           paddingX: "6px",
    //           textTransform: "uppercase",
    //           backgroundColor: `${params.row.completed[0]?.submitted
    //             ? "green"
    //             : params.row.delayed
    //               ? "red"
    //               : "#c3b50d"
    //             }`,
    //         }}
    //       >
    //         {params.row.status}
    //       </Box>
    //     );
    //   },
    // },
    { field: "assessmentStatus", headerName: "Assessment Status", editable: false, minWidth: 150, headerAlign: 'left', align: 'left', flex: 1 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      sortable: false,
      headerAlign: 'center', align: 'center', flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const handleAnswerClick = (id, email) => {
    navigate(`/dashboard/view-answer-page/${id}`, { state: {email,prevUrl:'/dashboard/assessment' } });
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleActionClick = (event, row) => {
    setDeadline(moment.unix(row.deadline).format('YYYY-MM-DD'));
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setSelectedRow(null);
    setDeadline(null);
    setExtendDeadline(null);
    setAnchorEl(null);
  };
  useEffect(() => {
    getAssessment();
  }, []);

  const getAssessment = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ASSESSMENT}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data;
              console.log(data)
            setisNotFound(!resChart.data.data);
            setAssessments(data);
            setRows(
              data.length
                ? [
                  ...data?.map((d, i) => ({
                    ...d,
                    uid: i + 1,
                    id: d._id,
                    lastDate: moment.unix(d.deadline).format("DD/MM/YYYY"),
                    assessmentStatus:d.assessmentStatus,
                    status: d.completed[0]?.submitted
                      ? "Completed"
                      : d.delayed
                        ? "Delayed"
                        : "In-Progress",

                  })),

                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const deleteAssessmentAPI = async () => {
    const payload = {
      assessmentId: activeAssessment._id,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_ASSESSMENT}`,
          payload,
          {}
        )
          .then((resChart) => {
            setShowLoader(false);
            setShowDeleteAssessmentModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove this assessment ?
        </h2>
      </Typography>
    );
  };

  const extendsDeadlineDateAPI = async (e) => {
    if (!extendDeadline) {
      enqueueSnackbar('Please select the extend deadline date', { variant: 'error' })
      return false;
    }
    if (extendDeadline <= deadline) {
      enqueueSnackbar('Extend deadline date should be greater then the deadline date', { variant: 'error' })
      return false;
    }
    // Convert the date strings to Unix timestamps
    const deadlineUnix = moment(deadline, "YYYY-MM-DD").unix();
    const extendDeadlineUnix = moment(extendDeadline, "YYYY-MM-DD").unix();
  
    console.log("deadline" , deadlineUnix)
    console.log("extendDeadline" , extendDeadlineUnix);

    console.log("activeAssessment",activeAssessment)


    const payload = {
      assessment_id: activeAssessment._id,
      deadline: extendDeadlineUnix,
      extendedDeadlineCount:activeAssessment.extendedDeadlineCount?(activeAssessment.extendedDeadlineCount+1):1
    };
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_DEADLINE}`,
          // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"update-assessment-deadline"}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;
            setShowLoader(false);
            setShowExtendsDeadlineModal(false)
            enqueueSnackbar(res.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((err) => {
            enqueueSnackbar(err.data?.message, { variant: "error" });
            setShowLoader(false);
          }); 
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }
  

  const acceptDeclineAssessment = async (e) => {    
    const payload = {
      assessment_id:activeAssessment._id,
      assessmentStatus: assessmentApprovedDeclinedStatus
    };
    console.log(payload);
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_STATUS}`,
          // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"update-assessment-status"}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;
            
            setShowLoader(false);
            enqueueSnackbar(res.data?.message, { variant: "success" });
            setShowAcceptDeclineModal(false)
            getAssessment()
          })
          .catch((err) => {
            enqueueSnackbar(err?.message, { variant: "error" });
            setShowLoader(false);
          }); 
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
    setShowAcceptDeclineModal(false)
  }

  const handleExtendDeadlineChange = (event) => {
    setExtendDeadline(event.target.value);
  };
  const handleAssesmentStatusChange = (event) => {
    setAssessmentApprovedDeclinedStatus(event.target.value);
  };
  const getCurrentDate = (date) => {
    let parseDate = date
    if (date !== deadline) parseDate = moment(parseDate).add(1, 'day')
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Helmet>
        <title> Assessments | Genesis Platform</title>
      </Helmet>
        <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Assessment" />   
        <Grid container spacing={3} sx={{ mb: 0 }} className="ipad-space">
        <Grid item xs={12} md={12}>
        <Button
            variant="contained"
            className="addnew send-assessment"
            onClick={() => setSendAssessmentModal(true)}
          >
            Send Assessment
            </Button>
           <br></br>
        {/* {showLoader ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "290px" }}>
          <CircularProgress />
        </Box> : */}
            <Box
          className="bg-dark-assessment"
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            border: "none",
            borderRadius: "15px",
          }}
        >
          <div style={{ width: "100%" }}>
            <DataGrid
              sx={{ border: "none", width: "100%", height: "400px" }}
              rows={rows}
              columns={columns}
              components={{
                NoRowsOverlay: () => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                  >
                    No data
                  </Box>
                ),
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [],
                  },
                },
              }}
              autosizeOptions={{
                columns: ["id", "firstName", "lastName"],
                includeOutliers: true,
                includeHeaders: false,
              }}
              pageSizeOptions={[5, 10, 25]}
              slots={{
                toolbar: GridToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: DataGridLoader

              }}
              loading={showLoader}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
            />
          </div>
          {selectedRow && (
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleActionClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(`/dashboard/security-control/${selectedRow._id}`);
                }}
              >
                <SecurityIcon />
                Security
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  handleAnswerClick(selectedRow._id, selectedRow.email);
                }}
              >
                <VisibilityIcon />
                Visibility
              </MenuItem>
              {((selectedRow.assessmentStatus === "In Progress" ||  selectedRow.assessmentStatus === "Extended Deadline" || selectedRow.assessmentStatus === "Sent") &&selectedRow.extendedDeadlineCount <2 ) ?<MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowExtendsDeadlineModal(true);
                }}
              >
                <AccessTimeIcon />
                Extend Deadline
              </MenuItem>:null}
              {(selectedRow.assessmentStatus === 'Completed' || selectedRow.assessmentStatus ===  'Engagement') && (
                <MenuItem
                  onClick={() => {
                    setActiveAssessment(selectedRow);
                    setShowAcceptDeclineModal(true);
                  }}
                >
                  <AccessTimeIcon />
                  Accept/Decline
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowDeleteAssessmentModal(true);
                }}
              >
                <DeleteIcon />
                Remove
              </MenuItem>
            </Menu>
          )}
           </Box>
          </Grid>
        </Grid>
      </Container>

      <ShowAssessmentModal
        handleClose={() => setShowAssessmentDetailModal(false)}
        open={showAssessmentDetailModal}
        data={activeAssessment}
      />
      <ModalDialog
        handleClose={() => setShowDeleteAssessmentModal(false)}
        open={showDeleteAssessmentModal}
        onSubmit={() => deleteAssessmentAPI()}
        contentData={ModalContentAssessment()}
      />
      <ExtendDeadlineModel

        handleClose={() => setShowExtendsDeadlineModal(false)}
        open={showExtendsDeadlineModal}
        onSubmit={() => extendsDeadlineDateAPI()}
        deadline={deadline}
        // contentData={ModalContentExtendDeadlineDate(deadline)}
        handleExtendDeadlineChange={handleExtendDeadlineChange}
        getCurrentDate={getCurrentDate}
      />
      <ApproveDeclineAssesmentModel
        handleClose={() => setShowAcceptDeclineModal(false)}
        open={showAcceptDeclineModal}
        assessmentApprovedDeclinedStatus = {assessmentApprovedDeclinedStatus}
        onSubmit={() => acceptDeclineAssessment()}
        handleAssesmentStatusChange={handleAssesmentStatusChange}
      />
      {sendAssessmentModal ? (
        <SendAssessmentModal
          handleClose={() => setSendAssessmentModal(false)}
          open={sendAssessmentModal}
          data={null}
        />
      ) : null}
    </>
  );
}
