import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/AddFile.css";
// @mui
import { LoadingButton } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
// components
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import NewHeader from "../layouts/dashboard/header/NewHeader";

export default function AddFilePage() {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [imageData, setImageData] = useState();
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleImageChange = async (e) => {
    setshowLoader(true);

    e.preventDefault();
    setImageData();
    setSelectedFileName(null);

    let files = e.target.files[0];
    if (files) {
      let dataImage = new FormData();
      dataImage.append("user_file", e.target.files[0]);

      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (fileExtension === "json") {
        if (fileSize <= 20000) {
          setImageData(dataImage);
          setSelectedFileName(e.target.files[0].name);
          setshowLoader(false);
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          setshowLoader(false);
        }
      }
    }
  };

  const do_upload = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endPoint = process.env.REACT_APP_SAVE_VENDOR_BY_FILE;
        if (value === "2") {
          endPoint = process.env.REACT_APP_SAVE_ATTACK_FILE;
        }
        let temp = imageData;
        temp.append("email", email);
        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          temp,
          "ImageUpload"
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar("File uploaded successfully.", {
              variant: "success",
            });
            setshowLoader(false);
            setImageData();
            setSelectedFileName(null);
          } else {
            enqueueSnackbar("File not uploaded, some keys are missing", {
              variant: "error",
            });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar(uploadJson.data.message, { variant: "error" });
          setshowLoader(false);
        }
      }
    } catch (e) {
      enqueueSnackbar("Error in upload file", { variant: "error" });
      setshowLoader(false);
    }
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setImageData();
    setSelectedFileName(null);
  };
  const [email, setEmail] = useState("");

  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Dashboard: JSON FILE | Genesis Platform</title>
      </Helmet>

      <Container maxWidth={false}>
        <div>
        <NewHeader titleName="Upload JSON"/>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space tabspart"
          >
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Vendors" value="1" />
                <Tab label="Attack" value="2" />
              </TabList>
              <div className="bgaddfiles">
                <TabPanel value="1">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      className="centerpoint"
                    >
                      <h2 className="heading-main ">
                        Select a Vendor's JSON File
                      </h2>
                    </Typography>
                  </Stack>
                  <Card sx={{ p: 0, m: 0, width: "100%" }}>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <FormControl sx={{ width: "100%" }}>
                            {/* <InputLabel htmlFor="my-input">Import File</InputLabel> */}
                            <form
                              onSubmit={(e) => {
                                do_upload(e);
                              }}
                            >
                              <input
                                id="file-upload"
                                type="file"
                                accept=".json"
                                onChange={(e) => handleImageChange(e)}
                                style={{ display: "none" }}
                              />
                              <Button
                                htmlFor="file-upload"
                                size="small"
                                variant="contained"
                                color="primary"
                                component="label"
                                className="select-jeson"
                              >
                                + Select Json File
                              </Button>
                              <label className="save-btn-block">
                                <b>
                                  {selectedFileName !== null ? "File : " : null}
                                </b>
                                {selectedFileName !== null
                                  ? selectedFileName
                                  : null}
                              </label>

                              {selectedFileName !== null ? (
                                <LoadingButton
                                  fullWidth
                                  size="small"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  className="save-file-btn"
                                  sx={{ width: "auto" }}
                                >
                                  Save
                                </LoadingButton>
                              ) : null}
                            </form>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </TabPanel>
                <TabPanel value="2">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      className="centerpoint"
                    >
                      <span className="heading-main">
                        Select an attack surface JSON file
                      </span>
                    </Typography>
                  </Stack>
                  <Card sx={{ p: 2, m: 0, width: "100%" }}>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <FormControl sx={{ width: "100%" }}>
                            {/* <InputLabel htmlFor="my-input">Import File</InputLabel> */}
                            <form
                              onSubmit={(e) => {
                                do_upload(e);
                              }}
                            >
                              {/* <Grid item xs={12} md={12} lg={12}>                       
                            </Grid> */}
                              <input
                                id="file-upload"
                                type="file"
                                accept=".json"
                                onChange={(e) => handleImageChange(e)}
                                style={{ display: "none" }}
                              />
                              <Button
                                htmlFor="file-upload"
                                size="small"
                                variant="contained"
                                color="primary"
                                component="label"
                                className="select-jeson"
                              >
                                + Select Json File
                              </Button>

                              <label className="save-btn-block">
                                <b>
                                  {selectedFileName !== null ? "File : " : null}
                                </b>
                                {selectedFileName !== null
                                  ? selectedFileName
                                  : null}
                              </label>

                              {selectedFileName !== null ? (
                                <LoadingButton
                                  fullWidth
                                  size="small"
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  className="save-file-btn"
                                  sx={{ width: "auto" }}
                                >
                                  Save
                                </LoadingButton>
                              ) : null}
                            </form>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </TabPanel>
              </div>
            </TabContext>
          </Box>
        </div>
      </Container>
    </>
  );
}
