import './css/globalFullSearchInput.css';
import GlobalSearchBar from './globalSearchBar';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function GlobalFullSearchInputPage() {
    const [searchInputData, setSearchInputData] = useState('');  
    const location = useLocation();  
    const navigate = useNavigate();  

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchValue = params.get('search');
        if (searchValue) {
            setSearchInputData(searchValue);  
        }
    }, [location]);

    const handleSearchChange = (newSearchValue) => {
        setSearchInputData(newSearchValue);  
        
        const params = new URLSearchParams(location.search);
        if (newSearchValue) {
            params.set('search', newSearchValue);
        } else {
            params.delete('search'); 
        }
        navigate(`?${params.toString()}`);  
    };

    return (
        <div className="global-full-search-input">
            <GlobalSearchBar 
                isFullPage={true} 
                searchInputData={searchInputData}  
                onSearchChange={handleSearchChange}  
            />
        </div>
    );
}
