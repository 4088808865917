import React, { useState, useEffect } from 'react';
import { Box, Modal, Card } from "@mui/material";
import HeaderIcon from '../assets/search-icons.svg';
import SparkleIcon from '../assets/Sparkle.png';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import Markdown from 'marked-react';
import GetRequest from '../components/apiConnections/getRequest';
import './css/globalSearchBar.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from 'react-router-dom';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#120A3A",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    height: "80vh",
    overflowY: "auto",
};

export default function GlobalSearchBar({ isFullPage, searchInputData, onSearchChange }) {
    const [suggestions, setSuggestions] = useState([]);
    const [modalOpen, setModalOpen] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const searchOpenAiChatGPT = () => {
        setLoading(true);

        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SEARCH_OPEN_AI_CHAT_GPT}?prompt=${prompt}`;

        GetRequest(url, {})
            .then((response) => {
                setModalOpen(response?.data);
                setLoading(false);
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: "error" });
                setLoading(false);
            });
    };

    const globalSearchAPI = () => {
        setLoading(true);

        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_GLOBAL_SEARCH}?search=${search}`;

        GetRequest(url, {})
            .then((response) => {

                if (Array.isArray(response.data.data)) {
                    const fetchedSuggestions = response.data.data.map(item => ({
                        matchedSnippet: item.matchedSnippet,
                        matchedFieldValue: item.matchedFieldValue
                    }));
                    fetchedSuggestions.push({
                        matchedSnippet: "Ask to Genesis AI Assistant"
                    });
                    setSuggestions(fetchedSuggestions);
                    setLoading(false);
                }
            })
            .catch((error) => {
                const fallbackSuggestions = [{
                    matchedSnippet: "Ask to Genesis AI Assistant"
                }];

                setSuggestions(fallbackSuggestions);
                setLoading(false);
            });
    };

    const handleSuggestionClick = (suggestion) => {
        if (suggestion.matchedSnippet === "Ask to Genesis AI Assistant") {
            searchOpenAiChatGPT();
            setModalOpen('');
        } else {
            setModalOpen(suggestion.matchedFieldValue);
        }
    };

    const handleCloseButton = () => {
        setModalOpen(null);
    };

    const cleanText = (text) => {
        return text?.replace(/[^a-zA-Z0-9 ]/g, '');
    };

    useEffect(() => {
        const querySearch = searchParams.get('search');
        if (querySearch) {
            setSearch(querySearch);
            setPrompt(querySearch);
        }
        if (querySearch?.length > 0 || search?.length > 0 || searchInputData?.length > 0) {
            globalSearchAPI(querySearch || search || searchInputData);
        }
    }, [searchParams, search, searchInputData]);

    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);
        setPrompt(inputValue);
        if (onSearchChange) {
            onSearchChange(inputValue);
        }
    };

    const handleIconClick = () => {
        navigate(`/dashboard/global-full-search-input?search=${search}`);
    }
    return (
        <>
            <div className='relative-search'>
                <input
                    className={`input-header-search ${(search?.length > 0 || prompt?.length > 0) ? 'input-header-search-active' : ''}`}
                    type='text'
                    placeholder='Search'
                    value={search || prompt || searchInputData}
                    onChange={handleSearchInputChange}

                />

                {!isFullPage ? (
                    <img onClick={handleIconClick} src={HeaderIcon} alt='icon' />
                ) : null}

                {(search?.length > 0) && suggestions?.length > 0 && (
                    <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                            suggestion.matchedSnippet && (
                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion.matchedSnippet === "Ask to Genesis AI Assistant" ? (
                                        <span>
                                            <img
                                                className='sparkle-icon'
                                                width="15px"
                                                height="15px"
                                                src={SparkleIcon}
                                                alt="icon"
                                            />
                                            {cleanText(suggestion.matchedSnippet.substring(0, 30)) + '...'}
                                        </span>
                                    ) : (
                                        cleanText(suggestion.matchedSnippet.substring(0, 30) + '...')
                                    )}
                                </li>
                            )
                        ))}
                    </ul>
                )}
            </div>

            <Modal
                open={modalOpen !== null}
                onClose={() => setModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme == "Dark"
                        ? "dark-attack-modal modal-popup"
                        : "light-attack-modal modal-popup"
                }
            >
                <Box sx={style} className="black-bg attackpart">
                    <CloseIcon 
                        onClick={handleCloseButton}
                        style={{ position: 'absolute', right: 15, top: 15, cursor: 'pointer' }}
                    />
                    {loading ? (
                        <>
                            <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                            <span style={{ marginLeft: "8px" }}>Loading...</span>
                        </>
                    ) : (
                        (typeof modalOpen === 'string' || modalOpen) ? (
                                <Markdown value={modalOpen?.data || modalOpen} />
                        ) : null
                    )}

                </Box>
            </Modal>
        </>
    );
}
