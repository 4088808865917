import { useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid
} from "@mui/material";
import { LoginForm } from "../sections/auth/login";
import "./css/LoginPage.css";
import fullDark from "../assets/full-dark.png";
import fullLight from "../assets/full-light.png";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage({ toggleTheme }) {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "Dark");

  const toggleClick = () => {
    setTheme(theme === "Dark" ? `Light` : `Dark`);
    toggleTheme();
  };
  return (
    <>
      <Helmet>
        <title> Login | Genesis Platform </title>
      </Helmet>
      <div
        className={`login-screen ` + (theme === "Dark" ? "body-dark" : "body-light")}>
        <Container maxWidth={false}  className="login-right-padding">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <StyledRoot>
               <StyledContent>
                <Typography variant="h4" gutterBottom>
                 <img
                  src={theme === "Dark" ? fullDark : fullLight}
                  className="logo-size"
                  alt="logo"
                  />
                </Typography>
              <h2>Log in to your account</h2>
              <LoginForm toggleTheme={() => toggleClick()} />
              </StyledContent>
              </StyledRoot>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <div className="bg-login-new">
                  <div className="fit-login-area">
                    <h2>We are glad to see you again!</h2>
                    <h1>Empowering You to Manage Third-Party Risks with Confidence</h1>
                  </div>
                </div>
              </Grid>
             </Grid>
          </Container>
       </div>
    </>
  );
}
