import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Card, CardHeader, Container, Grid, Typography, Box, CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import "./css/Dashboard.css";
import ReportChart from "./ReportChart";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from '../components/apiConnections/getRequest';
import IconDashbaord from '../assets/icon-third-party.svg';
import UpIcon from '../assets/up-icon.svg';
import DownIcon from '../assets/downside-arrow.svg';
import Chart from "react-apexcharts";
import GaugeChart from "../pages/GuageGraph";
import ScoreUp from '../assets/score-icon.svg';
import Figma from '../assets/figma-icon.png';
import HamburgerMenu from "./HamburgerMenu";
import NewHeader from '../layouts/dashboard/header/NewHeader.js'
import NewOverall from '../sections/@dashboard/app/NewOverall.js';

import {
  AppCurrentVisits,
  AppOverallScore,
  AppWebsiteVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";
import { useTheme } from "@mui/material/styles";

export default function DashboardAppPage() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [totalApps, setTotalApps] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [completedAssessment, setCompletedAssessment] = useState(0);
  const [totalAssessments, setTotalAssessments] = useState(0);
  const [delayedAssessment, setDelayedAssessment] = useState(0);
  const [ongoingAssessment, setOngoingAssessment] = useState(0);
  // const [totalAssessment, setTotalAssessment] = useState();
  const [severityIssue, setSeverityIssue] = useState({});
  const [thirdPartyRisk, setThirdPartyRisk] = useState([]);
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([]);
  const [allAssessmentsCount, setAllAssessmentsCount] = useState([]);
  const [option, setOption] = useState(365);
  const [issueSeverityLoader, setIssueSeverityLoader] = useState(false);
  const [thirdPartyRiskLoader, setThirdPartyRiskLoader] = useState(false);
  const [chartDataLoader, setChartDataLoader] = useState(false);
  const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
  const [thirdPartyRiskConfig, setThirdPartyRiskConfig] = useState([]);
  const [percentageChangeInVendor, setPercentageChangeInVendor] = useState(0);
  const [submittedAssessment, setSubmittedAssessment] = useState(0);
  const [dashboardCardData, setDashboardCardData] = useState(""); 


  useEffect(() => {
    getChartData();
  }, [option]);

  useEffect(() => {
    getDashboardCardData();
    getIssueSeverityData();
    getThirdPartyAtRiskData();
  }, [])
  const totalIssuesChange = async (event) => {
    // 👇 Get input value from "event"
    setOption(event.target.value);
  };

  const getChartData = async () => {
    setChartDataLoader(true);
    const payload = {
      selectedDays: option,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_LAST_N_DAYS_ISSUES;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((resChart) => {
            let response = resChart.data.data;
            let allDates = [];
            let allIssues = [];
            if (response?.totalIssue) {
              response?.totalIssue.map((datas) => {
                allDates.push(datas.date)
                allIssues.push(datas.calculateIssue)
              });
              setGraphDates(allDates);
              setGraphValues(allIssues);
              // setGraphValues([10,4,5,7]);

              setChartDataLoader(false);
            } else {
              enqueueSnackbar("No data found for monthly chart.", {
                variant: "error",
              });
              setChartDataLoader(false);
            }

            if (response?.totalAssessment) {
              let allAssessment = [];
              const totalAssessments = response?.totalAssessment || [];
              totalAssessments.forEach((assessment) => {
                allAssessment.push(assessment.count);
              });
              setAllAssessmentsCount(allAssessment);
              // setAllAssessmentsCount([8,7,9,10]);
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setChartDataLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setChartDataLoader(false);
    }
  };

  const getDashboardCardData = async () => {
    setDashboardDataLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_DASHBOARD_CARD_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {  
          setDashboardCardData(resDashboard.data.data)
          const response = resDashboard?.data?.data;
          setTotalIssues(response?.totalIssues);
          setTotalApps(response?.totalApps);
          setPercentageChangeInVendor(response?.percentageChangeInVendor);
          setTotalAssessments(response?.totalAssessments || 0);
          setCompletedAssessment(response?.completedAssessment || 0);
          setDelayedAssessment(response?.delayedAssessment || 0);
          setOngoingAssessment(response?.ongoingAssessment || 0);
          setSubmittedAssessment(response?.submittedAssessment || 0 );
          setDashboardDataLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setDashboardDataLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setDashboardDataLoader(false);
    }
  };
  
  const getIssueSeverityData = async () => {
    setIssueSeverityLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_ISSUE_SEVERITY_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setSeverityIssue(response);
          setIssueSeverityLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setIssueSeverityLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setIssueSeverityLoader(false);
    }
  };

  const getThirdPartyAtRiskData = async () => {
    setThirdPartyRiskLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setThirdPartyRisk(response);
          let arr = [
            { vendor: "Critical", score: 0 },
            { vendor: "High", score: 0 },
            { vendor: "Medium", score: 0 },
            { vendor: "Low", score: 0 },
          ];
          for (let item of response) {
            switch (true) {
              case item.score <= 25: arr[0].score++;
                break;
              case item.score <= 50: arr[1].score++;
                break;
              case item.score <= 75: arr[2].score++;
                break;
              case item.score <= 100: arr[3].score++;
                break;
              default:
                break;
            }
          }
          setThirdPartyRiskConfig([...arr]);
          setThirdPartyRiskLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setThirdPartyRiskLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setThirdPartyRiskLoader(false);
    }
  };

  /*First chart*/
  const chartOptions = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: [`Critical ${severityIssue?.critical || 0}`,
    `High ${severityIssue?.high || 0}`,
    `Medium ${severityIssue?.medium || 0}`,
    `Low ${severityIssue?.low || 0}`],
    colors: ['#CC2121', '#BA5705', '#BFB608', '#3C851F'],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => totalIssues,
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 80, // Also ensure the total label is moved below
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };


  const chartSeries = [severityIssue?.critical || 0, severityIssue?.high || 0, severityIssue?.medium || 0, severityIssue?.low || 0];
  /*Close first chart*/


  /*First chart*/
  const chartOptions2 = {
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: [`In-Progress ${ongoingAssessment || 0 }`, `Delayed ${delayedAssessment || 0 }`, `Submitted ${submittedAssessment || 0 }`, `Completed ${completedAssessment || 0 }`],
    colors: ['#CC2121', '#BA5705', '#BFB608', '#3C851F'],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => totalAssessments,
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 80, // Also ensure the total label is moved below
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };

  const chartSeries2 = [ongoingAssessment || 0 , delayedAssessment || 0 , submittedAssessment || 0 , completedAssessment || 0 ];
  /*Close first chart*/
  const isChartSeriesNullDonut = chartSeries.every(value => value === 0);
  const isChartSeries2NullDonut = chartSeries2.every(value => value === 0);

  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut
    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            total: {
              show: true,
              label: 'No Data',
              fontSize: '16px',
              color: '#fff', // Muted color for "No Data" text
              fontFamily: 'Mona-Sans, sans-serif',
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff', // Muted color for total value
              },
              formatter: () => '0', // Show "0" for total when no data
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    }
  });
  

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Dashboard" />
         <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Total Third Party Apps</h2>
                {( dashboardDataLoader|| issueSeverityLoader )?  (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center",minHeight: "190px",width: "100%" }}>
                        <CircularProgress />
                      </Box>
                  ) : (
                <div className="image-third-party">
                  <img src={IconDashbaord} alt='icon' className="image-third-party-icon"/>
                  <p>{totalApps?.toString()}</p>
                  {
                    percentageChangeInVendor > 0 ? (
                      <span className="number-up">
                        <img src={UpIcon} alt="up icon" /> {percentageChangeInVendor?.toFixed(2)?.toString()}
                      </span>
                    ) : (
                      <span className="number-down">
                        <img src={DownIcon} alt="down icon" /> {percentageChangeInVendor?.toFixed(2)?.toString()}
                      </span>
                    )
                  }
                </div>
                  )}
              </div>
              {/*<AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total Third Party Apps"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
            </Card>
          </Grid>
       
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Total Issues</h2>
                <div className="total-issues-chart">
                  {(!severityIssue || !totalIssues || dashboardDataLoader|| issueSeverityLoader )?  (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center",minHeight: "190px", width: "100%" }}>
                        <CircularProgress />
                      </Box>
                  ) : (
                    <Chart
                    options={isChartSeriesNullDonut ? getEmptyDonutOptions(chartOptions) : chartOptions}
                    series={isChartSeriesNullDonut ? [1] : chartSeries} // Show an empty donut if no data
                    type="donut"
                    width="325"
                  />        
                )}
                </div>
              </div>
              {/* <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total Third Party Apps"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
            </Card>
          </Grid>
          
          <Grid item xs={12} md={4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard ">
                <h2>Total Assessment</h2>
                <div className="total-issues-chart">
                {(dashboardDataLoader || !dashboardCardData )?  (
                    <Box sx={{ display: "flex", justifyContent: "center",minHeight: "190px", alignItems: "center", width: "100%" }}>
                        <CircularProgress />
                      </Box>
                  ) : (   
                  <Chart       
                    options={isChartSeries2NullDonut ? getEmptyDonutOptions(chartOptions2) : chartOptions2}
                    series={isChartSeries2NullDonut ? [1] : chartSeries2} // Show an empty donut if no data
                    type="donut"
                    width="337"
                  />
                )}
                </div>
              </div>
              {/* <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Third Party Apps"
                total={totalApps.toString()}
              /> */}
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Third Party Apps"
                total={totalApps?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Issues"
                total={totalIssues?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Ongoing Assessment"
                total={ongoingAssessment?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Completed Assessment"
                total={completedAssessment?.toString()}
              />
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Delayed Assessment"
                total={delayedAssessment?.toString()}
              />
            </Card>
          </Grid> */}
          <Grid item xs={12} md={4} lg={4} className="fit-height" style={{ flexWrap: 'wrap' }}>
            {/* <AppOverallScore title="Overall Risk Score" riskScore="0" /> */}
            <Card sx={{ mb: 1 }} className="score-different-color">
              <div className="top-heading-dashboard bg-ellipsshape">
                <h2>Overall Risk Score</h2>
                 <div className="total-issues-chart overall-chart-center">
                  {/* <GaugeChart /> */}
                   <span className="number-up position-right-side-overall">
                     <img src={UpIcon} alt="up icon" /> 4.2%
                   </span>
                   <NewOverall/>
                 </div>
              </div>
            </Card>
            <div className="critical-risk-part border-gradient border-shape-color">
              <p>Critical Risk</p>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4} className="fit-height">
            <AppCurrentVisits
              loading={issueSeverityLoader}
              title="Third-Party Issue Severity"
              chartData={[
                { label: "Low", value: severityIssue?.low || 0 },
                { label: "Medium", value: severityIssue?.medium || 0 },
                { label: "High", value: severityIssue?.high || 0 },
                { label: "Critical", value: severityIssue?.critical || 0 },
              ]}
              chartColors={[
                "#6AA84F",
                "#FEB019",
                "#ff7300",
                "#d32f2f",
              ]}
              style={{}}
            />
          </Grid> */}

          <Grid item xs={12} md={8} lg={8} className="fit-height">
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Third Party Classification by Risk</h2>
                {/*<Chart options={options} series={series} type="bar" height={350} /> */}
                <div className="total-issues-chart in-chart-bar custom-line-third">
                  <ReportChart
                    loading={thirdPartyRiskLoader}
                    // title="Third Party Classification By Risk"
                    style={{}}
                    chartData={thirdPartyRiskConfig}
                  />
                </div>
              </div>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            className="fit-height"
            sx={{ flexDirection: 'column' }}
          >
            <Box component={Paper} sx={{ display: "flex", flexDirection: "column", height: "95%" }}
            >
              {/* <CardHeader title={"Third Party at Risk"} subheader={""} /> */}
              <div className="top-heading-dashboard">
                <h2>Third Party at Risk</h2>
              </div>
              <TableContainer className={theme.palette?.mode === "light" ? " third-party-risk-table-light" : ` third-party-risk-table`} >
                {thirdPartyRiskLoader ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "295px", width: "100%" }}>
                    <CircularProgress />
                  </Box>) : <>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className="head-th">
                        <TableCell className="min-width-s-number">S.No.</TableCell>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell className="min-width">Score</TableCell>
                        <TableCell>Risk</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ maxHeight: "100px" }}>

                      {thirdPartyRisk && thirdPartyRisk.length > 0 && thirdPartyRisk?.map((row, index) => (
                        <TableRow hover key={row.vendor} tabIndex={-1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell style={{ textTransform: "capitalize", display: "flex", alignItems: "center", gap: "5px" }}>
                            <span className="logo-vender-multiple">
                              <img src={Figma} /></span> {row.vendor}
                          </TableCell>
                          <TableCell>{row.score} <span className="score-value">
                            <img src={ScoreUp} alt="icon" /> 4.2%</span></TableCell>
                          <TableCell>
                            <span className="third-party-table-button critical-r">
                              <span>Critical</span>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {!thirdPartyRisk?.length ? <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px"
                  }}>No Data Available</Box> : null}
                </>
                }
              </TableContainer>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            className="mbottom flex-property w100"
          >
            {/* <div class="total_issues_select_wrap">
              <select
                className="total_issues_select"
                onChange={totalIssuesChange}
              >
                <option value={30}>Last Month</option>
                <option value={90}>Last 3 Months</option>
                <option value={180}>Last 6 Months</option>
                <option value={365} selected="true">
                  Last Year
                </option>
              </select>
            </div> */}
            <Card sx={{ mb: 1 }}>
              <div className="top-heading-dashboard">
                <h2>Total Issues</h2>
              </div>
              <div class="total-issues-chart in-chart-bar">
                {console.log("allAssessmentsCount", "graphValues", allAssessmentsCount, graphValues)}
                <AppWebsiteVisits
                  // title="Total Issues"
                  // subheader="Last 30 Days"
                  chartLabels={graphDates}
                  loading={chartDataLoader}
                  chartData={[
                    {
                      name: "Total Issues",
                      type: "area",
                      fill: "gradient",
                      data: graphValues,
                    },
                    {
                      name: "Total Assessments",
                      type: "area",
                      fill: "gradient",
                      data: allAssessmentsCount,
                    },
                  ]}
                  totalIssuesChange={totalIssuesChange}
                  option={option}
                />
              </div>
            </Card>
          </Grid>
          {/* <Link to="/dashboard/chat-app" className='chat-circle-right'><ChatIcon /></Link> */}
        </Grid>
      </Container>
    </>
  );
}
