import React, { useEffect, useState } from "react";
import Hamburger from '../assets/hamburger.svg';

function HamburgerMenu() {
    
    const [isClassToggled, setIsClassToggled] = useState(false);

  // Function to toggle the class menu hamberger
  const toggleClass = () => {
    setIsClassToggled((prevState) => !prevState);
  };

  useEffect(() => {
    const containerElement = document.querySelector('.MuiContainer-root');
    const navElement = document.querySelector('nav');
    const navElementTwo = document.querySelector('.topHeader-move');


    if (containerElement && navElement && navElementTwo ) {
      if (isClassToggled) {
        containerElement.classList.add('new-class');
        navElement.classList.add('another-new-class');
        navElementTwo.classList.add('header-move');
      } else {
        containerElement.classList.remove('new-class');
        navElement.classList.remove('another-new-class');
        navElementTwo.classList.remove('header-move');
      }
    }
  }, [isClassToggled]);

  return (
    <img className="hamberger-icon" src={Hamburger} alt="icon" onClick={toggleClass} />
  )
}

export default HamburgerMenu